import {
  auth
} from "../../firebase"

const state = () => ({
});

const getters = {


};

const actions = {

  async signUp({
    commit
  }, data) {
    console.log('sign Up', data.email, data.password)

    let error = {
      yes: false,
      data: null,
    }

    const newUser = await auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((user) => {
        console.log("user", user, commit);
        return user;

      })
      .catch((err) => {
        console.error(err);
        error.yes = true
        error.data = err
      });

    if (!error.yes) {
      await newUser.user.sendEmailVerification(
        // {url: "https://colombo-mail.web.app/myaccount"}
      ).then(() => {
        console.log("verification email sent")
      }).catch((error) => {
        console.error("error while sending verification email", error);
        error.yes = true
        error.data = error
      })
    }

    if (error.yes)
      throw new Error(error.data.message);
    return newUser

  },

  async signInWithEmail({
    commit
  }, {
    email,
    password
  }) {
    const newUser = await auth
      .signInWithEmailAndPassword(email, password)
      .then((userAuthenticated) => {
        console.log("store user", userAuthenticated.user.uid, commit);
        return userAuthenticated
      })
      .catch((error) => {
        console.error("error", error);
        return error

      });



    return newUser
  },
};



const mutations = {

};


export default {
  state,
  getters,
  actions,
  mutations
}