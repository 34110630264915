import { db } from "../firebase";
export const calculatorService = {
    getCalculationData,
    getallExportServices,
    // getColomboExpressAndUpdateBulkAndExpress,
    // mergeShipments
};

async function getCalculationData() {
    // console.log("getCalculationData");
    return await db
        .collection("pages")
        .doc("exportServiceData")
        .get()
        .then(response => {
            const data = response.data()

            return data;
        })
        .catch(error => {
            // console.error("error from firebase getCalculationData", error);
            return error;
        });
}

async function getallExportServices() {
    let allExportServices = [{}, {}, {}, {}, {}, {}, {}, {}, {}]
    return await db
        .collection("exportServices")
        .get()
        .then((querySnapShot) => {
            querySnapShot.forEach((doc) => {
                let data = {};
                data = doc.data()
                data.id = doc.id
                if (data.id == "colomboExpress") allExportServices[0] = data
                if (data.id == "colomboEconomy") allExportServices[1] = data
                if (data.id == "singaporeSimple") allExportServices[2] = data
                if (data.id == "singaporeSmart") allExportServices[3] = data
                // if (data.id == "swissFullyTracked") allExportServices[4] = data
                // if (data.id == "swissCountryTrack") allExportServices[5] = data
                if (data.id == "colomboSpecial") allExportServices[4] = data
                if (data.id == "colomboBulk") allExportServices[5] = data
                if (data.id == "hermies") allExportServices[6] = data
                if (data.id == "usps") allExportServices[7] = data
                if (data.id == "indiaPost") allExportServices[8] = data

            });
            console.log("allExportServices", allExportServices);
            return allExportServices
        })
        .catch((error) => {
            // console.error("error in allExportServices", error);
            return error
        });
}

// async function getColomboExpressAndUpdateBulkAndExpress() {
//     let colomboexpressdata = null
//     await db.collection("exportServices").doc("colomboSpecial").get().then((querySnapshot) => {
//         colomboexpressdata = querySnapshot.data();
//         console.log("colomboexpressdata", colomboexpressdata)

      

//         // let updateedShipment= mergeShipments(colomboexpressdata)
//         // console.log("updateedShipment", updateedShipment)
//         let colomboSpeciall =[]

//         db.collection("exportServices").doc("colomboSpecial").set(colomboSpeciall)
//     .then((res)=>{
//         console.log("colomboSpeciall data addeded",res)
//     })
//     .catch((err)=>{
//        console.log("Error in adding bulk data",err)
//     })

//     }).catch((error) => {
//         console.log("error", error)
//     });
    
// }



// async function mergeShipments(shipment) {
//     let newShipment = []
//     console.log("newshipmett", newShipment)
//     // Start zone counter at 5 (assuming there are zones 1-4 by default)
//     let zoneCounter = Object.keys(shipment.zones).length + 1;

//     // Create a mapping of existing countries in shipment by name (in lowercase for case-insensitive comparison)
//     const countryMapping = shipment.countries.reduce((acc, country) => {
//         acc[country.name.toLowerCase().trim()] = country;
//         return acc;
//     }, {});

//     console.log("countryMapping", countryMapping)

//     // Iterate over newShipment array
//     // Iterate over newShipment array
//     newShipment.forEach(entry => {
//         const countryName = entry.COUNTRY.toLowerCase();
//         console.log("countryName", countryName)
//         // console.log(`%c Country ${entry.Country} is NOT present in shipment data.`, 'background: #222; color: #bada55')
//         // Check if the country already exists in shipment data
//         if (countryMapping[countryName]) {
//             console.log(`Country ${entry.COUNTRY} is present in shipment data.`);

//             const country = countryMapping[countryName];
//             if (country.zone !== zoneCounter.toString()) {
//                 // Log if a country is assigned a new zone
//                 console.log(`Zone changed for country ${country.name} from Zone ${country.zone} to Zone ${zoneCounter}`);
//                 country.zone = zoneCounter; // Update zone for existing country
//             }
//         } else {
//             console.log(`%c Country ${entry.COUNTRY} is NOT present in shipment data.`,'background: #222; color: #bada55');

//             // Add new country to shipment.countries with new zone reference
//             console.log(`New country added: ${entry.COUNTRY} with Zone ${zoneCounter}`);
//             shipment.countries.push({
//                 code: entry.COUNTRY.substring(0, 2).toUpperCase(), // Retain existing country code if any
//                 name: entry.COUNTRY,
//                 zone: zoneCounter.toString()
//             });
//         }

//         // Create new zone with weights and prices from newShipment entry
//         const zoneName = `zone${zoneCounter}`;
//         shipment.zones[zoneName] = Object.keys(entry)
//             .filter(key => key !== 'COUNTRY')
//             .map(weight => ({
//                 weight: parseFloat(weight),
//                 price: parseFloat(entry[weight])
//             }));

//         // Increment zone counter for next unique country
//         zoneCounter++;
//     });

//     console.log("merge shipmet", shipment)

//     return shipment;
// }