<template>
  <div>
    <div id="auth-nav" color="#330063" dense dark>
      <!-- <div-nav-icon></div-nav-icon> -->

      <a href="/colombomail">
        <v-toolbar-title class="white--text"> Colombo Mail </v-toolbar-title>
      </a>
      <v-spacer></v-spacer>

      <v-btn color="#4E1E7A" class="white--text" to="/">Go to home</v-btn>
    </div>
    <router-view></router-view>
    <div class="footer-credits">
      Made With 💖 By
      <a href="https://www.vantageflow.in/" target="_blank">vantageflow.in</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "authLayout",
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
}
.footer-credits {
  background-color: #070021;
  text-align: center;
  color: #ffffff;
  width: 100vw;
  padding: 5px 0px;
}
#auth-nav {
  height: 8vh;
  width: 100vw;
  background-color: #330063;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px;
}
</style>
