<template>
  <v-sheet class="mx-auto">
    <v-slide-group v-model="model" class="pa-4" show-arrows>
      <v-slide-item v-for="(image, index) in bannerImages" :key="index">
        <v-card class="ma-4" height="210" width="530">
          <v-img :src="image"></v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
export default {
  name: "partnerCard",
  data: () => ({
    model: null,
    bannerImages: [
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FWish.png?alt=media&token=a2800cbc-f9f2-4ff2-a5fa-e3ac34bde6d8",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FWalmart.png?alt=media&token=ddc84410-796d-47df-b849-55f90c20a6f1",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FPayoneer.png?alt=media&token=7f8bc99e-d943-4797-9a71-543652090ebb",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FEtsy.png?alt=media&token=e87613ff-e6e7-45b5-95e0-4157ae0ed5e1",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FEbay.png?alt=media&token=e7abf078-9da2-4501-afc5-0c593d884878",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FBonanza.png?alt=media&token=759dbf88-ed15-49c5-90ec-5c9fb706ce9f",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FAmazon.png?alt=media&token=7cb25dc6-a673-4c24-90de-8471b6e8f930",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FAli%20Express.png?alt=media&token=48a5ea9e-1e92-4086-acb8-ba233c2f1297",
      "https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2Fbanners%2FPayapal.png?alt=media&token=d1a20249-aaa2-42f5-8531-4e5a17551d45",
    ],
  }),
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-evenly;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 30px;
  padding-top: 20px;
}
.image {
  padding-left: 20px;
  align-self: center;
}

@media (max-width: 780px) {
}
</style>