<template>
  <header>
    <div class="logo">
      <a href="/">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2FSuper_Save_Logo_Selected.png?alt=media&token=f7d7c48a-0cb3-4ee6-af62-83b4c1bcf9de"
          alt="logo"
        />
      </a>
    </div>
    <div class="nav-item">
      <v-icon>mdi-map-marker-outline</v-icon>
      <div class="nav-content">
        <h4>Location:</h4>
        <p>
          SUPER SAVE LANKA (PVT) LTD
          <br />
          No.4, COUNCIL AVENUE (NEAR DEHIWALA MUNICIPAL COUNCIL),
          <br />
          DEHIWALA, SRI LANKA
        </p>
      </div>
    </div>

    <div class="nav-item">
      <v-icon>mdi-email-outline</v-icon>
      <div class="nav-content">
        <h4>Email:</h4>
        <p>info@colombomail.lk</p>
      </div>
    </div>

    <div class="nav-item">
      <v-icon>mdi-phone</v-icon>
      <div class="nav-content">
        <h4>Call Us:</h4>
        <p>+94114896660 | +94755192192</p>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "detailsMenu",
};
</script>

<style lang="scss" scoped>
a{
  text-decoration: none;
}
header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 130px;
  margin: 0 auto;
  background: #fff;
}
header .logo {
  width: 20%;
  max-width: 250px;
}
header .logo img {
  min-width: 130px;
  width: 100%;
}
.nav-item {
  display: flex;
  align-items: flex-start;
  margin-inline: 10px;
  justify-content: space-between;
  color: #646161;
  font-weight: 500;
}
.nav-item .v-icon {
  font-size: 3rem;
  color: #580966;
  margin-inline: 10px;
}
.nav-item .nav-content p {
  color: #d80487;
  font-size: 0.8rem;
}
.nav-item .nav-content h4 {
  color: #580966;
}
@media (max-width: 786px) {
  .nav-item {
    display: none;
  }
  header {
    align-items: center;
    justify-content: center;
  }
  header .logo {
    width: 160px;
  }
}
</style>
