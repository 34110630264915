<template>
  <v-card id="notice">
    <div class="title">
      <h4>Special Notice</h4>
      <slot></slot>
    </div>
    <div class="content_111">
      <h2>{{ notice.title }}</h2>
      <p>{{ notice.description }}</p>
    </div>
  </v-card>
</template>

<script>
import { noticeService } from "../.././services";
export default {
  name: "noticeCard",
  created() {
    this.init();
  },
  data() {
    return {
      notice: null,
    };
  },
  methods: {
    async init() {
      console.log("init running");
      this.notice = await noticeService.get();
    },
  },
};
</script>

<style lang="scss" scoped>
#notice {
  background-color: #ffffff;
  border-radius: 20px;
  max-width: 650px;

  min-width: 180px;
  margin: 0 auto;
  width: 100%;
}
.title {
  padding: 20px;
  background-color: #ecc9fc;
  color: #7814a6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.3rem;
}

.content_111 {
  padding: 10px 40px 30px 40px;
  text-align: center;
  h2 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #a861c9;
  }
  p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .v-card {
    width: 100%;
  }
  .title {
    padding: 10px 20px;

    h4 {
      font-size: 1rem;
    }
  }

  .content_111 {
    h2 {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
}
</style>
