<template>
  <div class="container" id="home">
    <div class="left ml-10">
      <div class="con2">
        <!-- <img src="../../../assets/main/home/1.svg" alt="" /> -->
        <div class="cml-logo">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2FColombo_Mall_Logo.png?alt=media&token=8ec8dce4-3603-4568-997c-cffd859334ad"
            alt="logo"
          />
        </div>
      </div>
      <div class="con1">
        <!-- <div class="display-test cml-logo">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2FColombo_Mall_Logo.png?alt=media&token=8ec8dce4-3603-4568-997c-cffd859334ad"
            alt="logo"
            width="80px"
          />
        </div> -->
        <h3>Your E-Commerce fulfillment</h3>
        <p class="text-center">
          Colombo Mail offers shipping, tracking and courier delivery services.
          Ship and track parcels and packages and learn about our express
          courier services!
        </p>
        <!-- <button class="goto-mall">Go to mall</button> -->
        <v-btn
          href="http://www.colombomall.lk"
          color="#8600D9"
          class="white--text"
          width="150"
          id="goto-mall"
        >
          Go to Mall
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="right ml-10">
      <!-- <div class="cnt cml-logo"> -->
      <!-- <img src="../../../assets/main/home/2.svg" alt="" /> -->
      <div class="cnt cml-logo">
        <img src="@/assets/home/colombo_mail.png" alt="logo" />
      </div>
      <!-- </div> -->
      <div class="cnt2">
        <!-- <div class="display-test">
          <img
            src="@/assets/home/ColomboLogo.svg"
            alt="logo"
            height="50"
            width="150"
          />
        </div> -->
        <h3>Your Logistics fulfillment</h3>
        <p class="text-center">
          Colombo Mail offers shipping, tracking and courier delivery services.
          Ship and track parcels and packages and learn about our express
          courier services!
        </p>
        <v-btn
          color="#8600D9"
          class="white--text"
          width="150"
          id="goto-mail"
          href="/colombomail"
        >
          Go to Mail
          <v-icon>mdi-truck-fast</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonSection",
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}

.container {
  display: flex;
  align-items: center;
  // justify-content: center;
}

//section2
.right {
  // padding-top: 30px;
  // display: flex;
  flex-direction: column;
  // height: 70vh;
  // margin-left: 15px;

  .cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 8px 40px;
    img {
      margin: 13% 12%;
      width: 100%;
      height: 100%;
    }
  }

  .cnt2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: flex-start;
    // margin-top: 5px;
    // height: 100%;
    width: 100%;
    align-items: center;

    h2 {
      font-size: 27px;
      color: #1f1f1f;
      font-weight: 600;
    }

    h3 {
      font-size: 25px;
      color: #292929;
      font-weight: 500;
    }

    p {
      color: #181818;
      font-weight: 400;
      font-size: 15px;
    }

    #goto-mail {
      padding: 10px;
      color: white;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  // .cml-logo {
  //   height: 350px;
  // }
}

.display-test {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.display-test img {
  // margin-inline: 10px;
  border-radius: 5px;

  box-shadow: 2px 3px 46px 6px rgba(250, 192, 229, 0.76);
  -webkit-box-shadow: 2px 3px 46px 6px rgba(250, 192, 229, 0.76);
  -moz-box-shadow: 2px 3px 46px 6px rgba(250, 192, 229, 0.76);
}

//section 1
.left {
  // height: auto;
  // padding: 0px;
  display: flex;
  flex-direction: column;
  // padding: 12px;
  // height: 70vh;

  .con1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // height: 100%;
    // padding-left: 5%;
    width: 50%;
    align-items: center;

    h2 {
      font-size: 27px;
      font-weight: 600;
      color: #1f1f1f;
    }

    h3 {
      font-size: 25px;
      color: #292929;
      font-weight: 500;
    }

    p {
      color: #141414;
      font-weight: 400;
      font-size: 16px;
    }

    #goto-mall {
      padding: 10px;
      color: white;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .con2 {
    // padding: 12%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 5% 12%;
    // width: 50%;

    img {
      padding: 5% 12%;
      width: 100%;
      height: 100%;
    }
  }
}

/*  Media queries */
@media (max-width: 780px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .right {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cnt {
    height: 17vh !important;
    // margin-top: 15px;
    width: 100%;

    img {
      padding: 0% 12%;
      margin-top: 15px;
      width: 100% !important;
      height: 100% !important;
    }
  }
  .display-test img {
    margin-top: 10px;
  }
  .cnt2 {
    justify-content: center;
    align-items: center !important;
    height: 25vh !important;
    width: 100vw !important;
    margin-top: 25px;

    h2 {
      font-size: 21px !important;
    }

    h3 {
      font-size: 14px !important;
    }

    p {
      font-size: 13px !important;
      text-align: center;
      width: 92% !important;
      max-width: 500px !important;
    }
  }

  //section1
  .left {
    height: 70%;
    // padding: 0px 0px !important;
    display: flex;
    // flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .con1 {
    justify-content: center;
    align-items: center !important;
    height: 25vh !important;
    width: 100vw !important;
    margin-top: 25px;

    h2 {
      font-size: 21px !important;
    }

    h3 {
      font-size: 14px !important;
    }

    p {
      font-size: 13px !important;
      text-align: center;
      width: 92% !important;
      max-width: 500px !important;
    }
  }

  .con2 {
    padding: 0% !important;
    // height: 40% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.cml-logo {
  width: 40%;
  height: 60%;
}
</style>
