import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from './firebase.js'
import { firestorePlugin } from 'vuefire';
// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

Vue.use(firestorePlugin);
Vue.config.productionTip = false;

// Vue.use(TawkMessengerVue, {
//   propertyId: '62deb76f37898912e95f9138',
//   widgetId: '1g8qvp362'
// });

let app;

auth.onAuthStateChanged((user) => {
  console.log('user', user);
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});


// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')