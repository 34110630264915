import {
    functions
} from "../firebase";
// import axios from 'axios';

export const otpService = {
    sendOtp,
    verifyOtp
}

async function sendOtp(phone) {
    const formattedPhone = parseInt(phone)
    let result = {
        success: false,
        msg: "",
        code: 0,

    }

    const sendOtpCF = functions.httpsCallable('sendOtp');
    await sendOtpCF({ phone: formattedPhone })
        .then((response) => {
            console.log("recived response from sendOtpCF", response);
            // Read response of the Cloud Function.
            let data = response.data;
            result.success = data.sent;
            result.msg = data.message;
            result.code = data.code;
        })
        .catch((err) => {
            result.success = false;
            result.msg = "Failed to send OTP";
            result.code = 0;
            console.error("Error while sending data", err.code, err.message);
        })


    return result
}


async function verifyOtp(otp) {
    console.log("verifyOtp called", otp);
    const formattedOtp = parseInt(otp)
    let result = {
        success: false,
        msg: "",
    }
    const verifyOtpCF = functions.httpsCallable('verifyOtp');
    await verifyOtpCF({ otp: formattedOtp }).then((response) => {
        // Read response of the Cloud Function.
        var data = response.data;
        console.log("data", data);
        if (data.verified == true) {
            result.success = true;
            result.msg = "OTP verified successfully";
        } else
            result.msg = data.message;
    }).catch((err) => {
        console.error("Error cactched in cloud function reuest", err.code, err.message);
        result.success = false;
        result.msg = "Error while verifying OTP";
    })






    return {
        verified: result.success,
        msg: result.msg,
        code: 1
    }

}


//correct result in verify otp
// remove phone from otp
//disable resend option
//set timer to 1:30
//resend only after 1:30s be
//check only authenticated users
//allow only authenticated users to call send otp
//!limit only phone in req data
//store verified phone in user profile