<template>
  <div class="navbar">
    <detailsMenu />
    <div class="cont">
      <div class="logo">Colombo Mail</div>
      <nav :class="{ navActive: navActive }">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/bulkcargo">Bulk Cargo</a></li>
          <li><a href="#contents">About</a></li>
          <li><a href="#why">Why us?</a></li>
          <li><a href="#contactus" class="footer">Contact us</a></li>
          <li><a href="/login">Login</a></li>
          <li><a href="/register" class="register">Register</a></li>
        </ul>
      </nav>
    </div>

    <div class="nav-toggle-div">
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
        class="nav-icon-toggle"
      ></v-app-bar-nav-icon>
      <v-navigation-drawer v-model="drawer" absolute temporary width="80%">
        <v-list nav align="center">
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>
                <a href="/bulkcargo" class="resp-links">Bulk Cargo</a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a href="#contents" class="resp-links">About</a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <a href="#form-section" class="resp-links">Service</a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <a href="#why" class="resp-links">Why us?</a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <a href="#contactus" class="resp-links">Contact Us</a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a href="/login" class="resp-links">
                  Login
                  <!-- <v-btn width="120" color="pink">Login</v-btn> -->
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a href="/register" class="login-btn">
                  Register
                  <!-- <v-btn width="120" color="#8600D9">Register</v-btn> -->
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <router-view></router-view>

    <!-- <div class="footer-credits">
      To access our site
      <a href="https://old.colombomail.lk" target="_blank">CLICK HERE</a>
      | Next 7 days you can access our old site
    </div> -->
  </div>
</template>

<script>
import detailsMenu from "../components/home/details.vue";

export default {
  name: "NavBar",
  components: {
    detailsMenu,
  },
  data() {
    return {
      navActive: false,
      drawer: false,
      group: null,
    };
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

// .footer-credits {
//   background-color: #ffb13d;
//   text-align: center;
//   color: #402b07;
//   width: 100vw;
//   padding: 5px 0px;
// }
a {
  font-weight: bold;
  color: #ffffff;
}
.navbar {
  z-index: 0;
}
.cont {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  width: 100vw;
  // height: 100%;
  margin: 0 auto;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-x: hidden;
  z-index: 2;
}

.cont .logo {
  font-weight: 600;
  font-size: 2.2rem;
  color: #fff;
  margin-top: 6px;
  // display: none;
}

.cont nav ul li a {
  text-decoration: none;
  color: #fff !important;
  font-size: 18px;
}

.cont nav {
  color: #fff;
  display: inline-block;
}

.cont nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cont nav ul li {
  display: inline-block;
  padding: 20px;
}

// Specific design
.register {
  background: rgba(255, 255, 255, 0.45);
  padding: 0.8em;
  border-radius: 2px;
}
.nav-toggle-div {
  display: none;
}
.v-list {
  transform: translateY(150px);
}
.resp-links {
  text-decoration: none;
  color: black;
  font-size: 18px;
}
.nav-icon-toggle {
  visibility: hidden;
}
// responsive design

@media (max-width: 786px) {
  .cont {
    display: none;
  }
  nav {
    background: #6358da;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.4s ease-in-out;
    // z-index: 9;
    transform: translateX(100%);
    overflow-x: hidden;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      flex-direction: column;
    }
  }
  .menu-icon {
    z-index: 10;
    height: 50px;
  }

  .navActive {
    transform: translateX(0%);
  }
  .nav-icon-toggle {
    position: absolute;
    visibility: visible;
    width: 50px;
    height: 50px;
    right: 40px;
    top: 19vh;
    z-index: 5;
    background: #6358da;
  }
  .nav-toggle-div {
    display: flex;
  }
}
</style>
