<template>
  <main>
    <div id="why">
      <h2>Intresting fact about us</h2>
      <div class="facts">
        <div class="fact">
          <h1>100k</h1>
          <h3>International Shipments</h3>
        </div>
        <div class="fact">
          <h1>100k</h1>
          <h3>Customers</h3>
        </div>
        <div class="fact">
          <h1>100k</h1>
          <h3>Domestic Shipments</h3>
        </div>
      </div>
    </div>
    <div class="map">
      <img src="@/assets/home/sectionThree/sec-3-map.svg" alt="" />
    </div>
  </main>
</template>

<script>
export default {
  name: "SectionThreeHome",
};
</script>

<style lang="scss" scoped>
main {
  background: linear-gradient(180deg, #de5b50 0.04%, #060051 100%);
  color: white;
  clip-path: polygon(0 100%, 100% 100%, 100% 15%, 0 0%);
  padding-top: 20vh;
  width: 100vw;
  height: 120vh;
  line-height: 1.8;

  h2,
  h3,
  h2 {
    font-weight: 400;
  }
}

#why {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-size: 1.8rem;
  }

  .facts {
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin-top: 4vh;
    text-align: center;

    .fact {
      h1 {
        font-size: 1.4rem;
      }

      h3 {
        font-size: 1.2em;
        border-bottom: 1px solid #2fde7a;
      }
    }
  }
}
.map {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  img {
    width: 100%;
    max-width: 1150px;
    min-width: 350px;
  }
}

// Media queries
@media (max-width: 786px) {
  * {
    font-size: 10px;
  }
  main {
    height: 90vh;
  }
  // .map {
  // }
  #why {
    .facts {
      width: 95%;
    }
  }
}
</style>