import {
  db,
  auth,
  functions
} from "../firebase";
export const orderService = {
  createNewOrder,
  getAllOrders,
  getSingleOrder,
};

async function getAllOrders() {
  console.log("getAllOrders");
  let userId = auth.currentUser.uid;
  return await db
    .collection("mailShipments").where("uid", "==", userId).orderBy("createdAt", "desc").get()
    .then((querySnapshot) => {
      const shipments = [];
      console.log("querySnapshot", querySnapshot);
      console.log("querySnapshot.docs", querySnapshot.docs);
      if (querySnapshot.length <= 0)
        return [];

      let shipment;
      querySnapshot.forEach((doc) => {
        shipment = doc.data();
        shipment.route = `myorders/${doc.id}`;
        shipments.push(shipment)
      })
      console.log("shipments", shipments);

      return shipments;
    }).catch((error) => {
      console.error("error", error);
      return error
    });
}

async function getSingleOrder(orderId) {
  console.log("getSingleOrder", orderId);
  let singleOrder = {};
  return await db
    .collection("mailShipments")
    .doc(orderId)
    .get()
    .then((doc) => {
      console.log("getSingleOrder", doc.data());

      singleOrder = doc.data();
      singleOrder.id = doc.id;
      return singleOrder
    }).catch((error) => {
      console.error("error", error);
      return error
    });
}

async function createNewOrder(orderData) {
  console.log("createNewOrder", orderData);
  const createOrderCF = functions.httpsCallable('createOrder');
  await createOrderCF({ ...orderData })
    .then((response) => {
      console.log("Create order response", response);
    })
    .catch((err) => {
      console.error("Create order error", err.code, err.message);
    })


}