<template>
  <div class="product">
    <v-card
      class="rounded-lg card-P"
      max-width="260"
      height="320"
      color="white"
    >
      <v-img height="200" src="@/assets/stall/shoes.png"></v-img>
      <h3 class="ml-4 title-card">{{ product.name }}</h3>
      <!-- <h3>NIKE Shoes | Runnig shoes | Mens | Luxury</h3> -->
      <v-card-text>
        <v-row align="center" class="mx-0">
          <v-rating
            value="4.5"
            color="amber"
            dense
            half-increments
            readonly
            size="15"
            v-model="rating"
          ></v-rating>
          <div class="grey--text ms-4">{{ rating }}</div>
        </v-row>
        <v-row>
          <div class="ms-4 rate">${{ product.price }}</div>
          <div class="ms-4 rate_1">
            <s>$4000</s>
          </div>
          <div class="ms-4 rate_2">20% off</div>
        </v-row>
        <v-row justify="end">
          <v-col>
            <!-- <v-btn
              class="ms-16"
              color="primary"
              dark
              outlined
              small
              @click="addToCart"
              ><v-icon left>mdi-cart</v-icon> Add To Cart</v-btn
            > -->
            <!-- <v-btn @click="addToCart" class="pink white--text"
              ><v-icon left>mdi-cart</v-icon> Add To Cart
            </v-btn> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  names: "product",
  props: ["product"],
  computed: {
    // ...mapGetters(["product"]),
  },
  methods: {
    ...mapActions(["addProductToCart"]),
    addToCart() {
      this.addProductToCart(this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-card {
  padding: 10px;
  margin-left: 10px;
  color:green;
}
.product {
  padding: 15px;
  align-items: center;
  img {
    width: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
  }
}
.v-btn {
  left: 25px;
  // padding: 5px;
}
h3 {
  color: rgb(22, 1, 1);
  padding: 6px;
}
.rate {
  color: #fb9600;
  font-size: 18px;
}
.rate_1 {
  color: #bdb8b8;
  font-size: 12px;
}

@media (max-width: 768px) {
  .card-P {
    width: 100%;
    max-width: 150px;
    height: 200px;
  }
  .rate_2 {
    color: black;
  }
}
</style>
