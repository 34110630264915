import {
    functions
} from "../firebase";
export const getInTouchService = {
    sendEmail,
};

async function sendEmail(getInTouchData) {
    console.log("sendEmail", getInTouchData);
    const createOrderCF = functions.httpsCallable('getInTouchEmail');
    await createOrderCF({ ...getInTouchData })
        .then((response) => {
            console.log("Create order response", response);
        })
        .catch((err) => {
            console.error("Create order error", err.code, err.message);
        })
}