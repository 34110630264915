<template>
  <div>
    <div class="cat">
      <div>
        <h3>
          <v-icon color="red">mdi-wall</v-icon>
          Categories
        </h3>
      </div>
      <div>
        <a href="#">
          <p>View all<v-icon>mdi-menu-right</v-icon></p>
        </a>
      </div>
    </div>
    <div class="tabs">
      <tabs />
      <!-- <v-tabs
        v-model="tab"
        centered
        color="red"
        dark
        v-on:click="tab = $event.target.innerText"
      >
        <v-tab>All</v-tab>
        <v-tab>Electronics</v-tab>
        <v-tab>Fashion</v-tab>
        <v-tab>Food</v-tab>
        <v-tab>Home</v-tab>
        <v-tab>Sports</v-tab>
      </v-tabs> -->
    </div>
  </div>
</template>

<script>
import tabs from "./tabs.vue";
export default {
  name: "categories",
  components: {
    tabs,
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.cat {
  // width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // justify-content: center;
  padding: 20px;
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #414141;
  }
  p {
    font-size: 15px;
    color: grey;
  }
  a {
    text-decoration: none;
  }
}
</style>
