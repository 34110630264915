<template>
  <main>
    <div class="containter">
      <div class="head d-flex ma-5">
        <div class="head1">
          <v-icon left class="mb-8 red--text" size="35">
            mdi-bag-personal</v-icon
          >
          <h2>My Orders</h2>
        </div>
      </div>
      <div class="tablecard">
        <div class="tableheading d-flex">
          <v-card-text class="font-weight-bold">Order #</v-card-text>
          <v-card-text class="font-weight-bold">Status</v-card-text>
          <v-card-text class="font-weight-bold">Date Purchased</v-card-text>
          <v-card-text class="font-weight-bold">Total</v-card-text>
        </div>
        <v-layout column wrap>
          <v-flex v-for="item in order" :key="item.name">
            <v-card>
              <div class="cardtext d-flex">
                <v-list-item-content
                  class="subtitle-1 font-weight-black pa-5"
                  >{{ item.Order }}</v-list-item-content
                >
                <v-list-item-content class="subtile-1 status">
                  {{ item.status }}</v-list-item-content
                >
                <v-list-item-content>
                  {{ item.datepurchased }}</v-list-item-content
                >
                <v-list-item-content> {{ item.total }}</v-list-item-content>
                <v-card-actions>
                  <v-btn text>
                    <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "allOrders",
  data() {
    return {
      order: [
        {
          Order: "U1PRL001",
          status: "primary",
          datepurchased: "Jan-10-2022",
          total: "$132",
        },
        {
          Order: "U1PCL002",
          status: "primary",
          datepurchased: "Feb-10-2022",
          total: "$192",
        },
        {
          Order: "U1PEL003",
          status: "primary",
          datepurchased: "Mar-10-2022",
          total: "$172",
        },
        {
          Order: "U1PAL004",
          status: "primary",
          datepurchased: "Apr-10-2022",
          total: "$102",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: space-between;
}
.head1 {
  display: flex;
  justify-content: start;
}
// .tableheading{
//     display: flex;
//     justify-content:space-around ;
// }
// .status {
//   background-color: #cfd8dc;
//   border-radius: 15px;
// }
.container {
  width: 150px;
}
.tablecard {
  width: 1200px;
  margin-left: 25px;
}
</style>