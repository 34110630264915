import {
    userService
} from "../../services"
const state = {
    profile: null,
}
const getters = {
    profile: state => state.profile,
};

const actions = {
    async fetchProfile({
        commit
    }) {
        console.log("fetchProfile called");
        const profile = await userService.getProfile()
        commit('setProfile', profile)
    }

};

const mutations = {
    setProfile: (state, profile) => (state.profile = profile),

};

export default {
    state,
    getters,
    actions,
    mutations
}