<template>
  <div>
    <template>
      <div class="itemsList">
        <!-- first part -->
        <div class="upper">
          <!-- <v-avatar color="grey" size="62">
            <span><v-icon color="blue">mdi-human-male-female</v-icon>Tops</span>
          </v-avatar>
          <v-avatar color="grey" size="62">
            <span
              ><v-icon color="blue">mdi-human-male-female</v-icon>Jackets</span
            >
          </v-avatar>
          <v-avatar color="grey" size="62">
            <span
              ><v-icon color="blue">mdi-human-male-female</v-icon>Sun
              Glasses</span
            >
          </v-avatar>
          <v-avatar color="grey" size="62">
            <span><v-icon color="blue">mdi-human-male-female</v-icon>Cap</span>
          </v-avatar> -->

          <!-- <p>
            <v-icon color="blue">mdi-human-male-female</v-icon>&nbsp;&nbsp;Tops
          </p>
          <p><v-icon color="blue">mdi-hanger</v-icon>&nbsp;&nbsp;Jackets</p>
          <p><v-icon color="blue">mdi-bus-side</v-icon>&nbsp;Watch</p>
          <p><v-icon color="blue">mdi-bus-side</v-icon>&nbsp;Sun Glasses</p>
          <p><v-icon color="blue">mdi-hanger</v-icon>&nbsp;Cap</p>
          <p><v-icon color="blue">mdi-hanger</v-icon>&nbsp;Nike Cap</p>
          <p><v-icon color="blue">mdi-hanger</v-icon>&nbsp;Gold</p> -->

          <p>
            <v-card>
              <v-btn>
                <v-icon color="blue">mdi-hanger</v-icon>&nbsp;&nbsp;Jackets
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-bus-side</v-icon>&nbsp;Watch
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-bus-side</v-icon>&nbsp;Sun Glasses
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-hanger</v-icon>&nbsp;Cap
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-hanger</v-icon>&nbsp;Nike Cap
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text
                ><v-icon color="blue">mdi-hanger</v-icon>&nbsp;Gold
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-human-male-female</v-icon>Cap
              </v-btn>
            </v-card>
          </p>
        </div>

        <!-- second part -->
        <div class="lower">
          <!-- <v-avatar color="grey" size="62">
            <span><v-icon color="blue">mdi-human-male-female</v-icon>Tops</span>
          </v-avatar>
          <v-avatar color="grey" size="62">
            <span><v-icon color="blue">mdi-human-male-female</v-icon>Tops</span>
          </v-avatar>
          <v-avatar color="grey" size="62">
            <span><v-icon color="blue">mdi-human-male-female</v-icon>Tops</span>
          </v-avatar>
          <v-avatar color="grey" size="62">
            <span><v-icon color="blue">mdi-human-male-female</v-icon>Tops</span>
          </v-avatar> -->

          <!-- <v-btn text v-for="(item, index) in items" :key="index">
            <v-icon v-for="(item, indexs) in items" :key="indexs">
              {{ item.avatar }}
            </v-icon>
            {{ item.title }}</v-btn
          > -->

          <p>
            <v-card>
              <v-btn text
                ><v-icon color="blue">mdi-hook</v-icon>Nike shoes</v-btn
              >
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-hanger</v-icon>&nbsp;Gold
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-hanger</v-icon>&nbsp;Hoody
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-shoe-sneaker</v-icon>&nbsp;shoes
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-human-male-female</v-icon
                >&nbsp;&nbsp;Tops
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text
                ><v-icon color="blue">mdi-bus-side</v-icon>&nbsp;bus
              </v-btn>
            </v-card>
          </p>
          <p>
            <v-card>
              <v-btn text>
                <v-icon color="blue">mdi-car</v-icon>&nbsp;car
              </v-btn>
            </v-card>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: "Click Me", avatar: "mdi-hook" },
      { title: "Click Me", avatar: "mdi-hook" },
      { title: "Click Me", avatar: "mdi-hook" },
      { title: "Click Me 2", avatar: "mdi-hook" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.v-card {
  width: 100%;
  height: 100%;
  // box-shadow: 0 35px 20px #777;
  // box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  //   0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
  //   0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  //   0 100px 80px rgba(0, 0, 0, 0.12);
  p {
    font-size: 20px;
    text-align: center;
  }
  // .v-card-title {
  //   font-size: 6px;
  //   color: grey;
  // }
}

.itemsList {
  //   display: flex;
  //   flex-wrap: column;
  //   flex-direction: row;
  //   justify-content:;
  //   align-items: center;
  //   padding: 10px;
  font-size: 15px;
}
.upper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.lower {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
@media (max-width: 780px) {
  .itemsList {
    // display: none;
  }
}
</style>
