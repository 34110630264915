import {
    otpService
} from "../../services";


const state = () => ({
});

const getters = {
};

const actions = {
    async sendOtp({
        commit
    }, phone) {
        console.log("sendOtp store called", phone, commit)
        const response = await otpService.sendOtp(phone)
        console.log('response', response)
        return response
    },

    async verifyOtp({
        commit
    }, otp) {
        console.log('commit', commit)
        console.log("verifyOtp store called", otp)

        const response = await otpService.verifyOtp(otp);
        console.log('response', response)

        return response
    },

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}