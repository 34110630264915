<template>
  <div>
    <!-- class="menu-bar"
      v-model="drawer"
      :mini-variant="miniVariant"
      :width="200"
      fixed
      app -->
    <v-navigation-drawer
      class="menu-bar"
      expand-on-hover
      mini-variant
      left
      permanent
      dark
      app
      color="deep-purple darken-1"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              src="https://scontent.fixm4-1.fna.fbcdn.net/v/t39.30808-6/fr/cp0/e15/q65/199009178_344893940588333_7731275416260037632_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=dd9801&_nc_ohc=bqhEsPkSOGUAX80z7VS&_nc_ht=scontent.fixm4-1.fna&oh=00_AT9Q8IB661EOLwnn9KAxuYkytAsE1WlFYJ02XhhUuQwZRw&oe=61D7FAC8"
            >
            </v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content v-if="profile">
            <v-list-item-title class="title" v-if="profile.firstname">
              {{ profile.firstname }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="profile.id">{{
              profile.id
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="list" nav dense>
        <v-list-item-group>
          <v-list-item
            color="#8600d9"
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            router
            exact
          >
            <v-icon class="mr-2">{{ item.icon }}</v-icon>

            <v-list-item-title v-text="item.title" />
          </v-list-item>
          <v-list-item color="#8600d9" router @click="logout" exact>
            <v-icon class="mr-2">mdi-exit-to-app</v-icon>

            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed dense app class="nav-bar" color="deep-purple darken-1">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="white--text">
        <router-link to="/colombomail" style="text-decoration: none">
          <b class="white--text">Colombo Mail</b>
        </router-link></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn class="add-shipment-btn" color="#F4BF00" to="/myOrders/addNew">
        <img src=".././assets/shipment-btn.svg" />
        Add Shipment
      </v-btn>
    </v-app-bar>

    <!-- Mobile app bar -->
    <v-list class="mobile-nav">
      <v-list-item
        color="purple darken-1"
        class="mobile-nav-list-item"
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
        router
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item>
    </v-list>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import "firebase/auth";
export default {
  name: "defaultLayout",
  data() {
    return {
      drawer: false,
      fixed: false,
      miniVariant: false,
      items: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          to: "/dashboard",
        },
        {
          icon: "mdi-office-building",
          title: "Order",
          to: "/myOrders",
        },
        {
          icon: "mdi-truck-fast",
          title: "Calculator",
          to: "/calculator",
        },
        // {
        //   icon: "mdi-shopping",
        //   title: "Stall",
        //   to: "/stall",
        // },
        {
          icon: "mdi-email-outline",
          title: "My Account",
          to: "/myAccount",
        },
      ],
      title: "ColomboMail",
    };
  },

  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // console.log("authChanged", this.isAuthenticated);
          this.$router.push("/");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.v-container {
  padding-top: 10vh;
}
.v-text-field {
  input {
    color: #41037b;
  }
}
.v-main {
  padding-top: 10vh;
  padding-left: 10vh;
}
.v-list-item__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 5%;
}
.menu-item {
  border-radius: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

// .menu-item:last-child {
//   position: absolute;
//   width: 90%;
//   bottom: -100%;
// }

.list {
  margin: 0% 0;
}
.v-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 2px 5px 6%;
}
.v-list-item-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
// .v-list-item:hover {
//   background: #8600d9;
// }

.v-list-item:hover .v-list-item__title,
.v-list-item:hover .v-icon {
  color: white;
}

.v-list-item .v-icon {
  transition: none !important;
}

.mobile-nav {
  display: none;
}

// tool bar tools
.nav-bar-tools {
  width: 20%;
  min-width: 500px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: 20px;

  * {
    margin-inline: 10px;
  }
}

.add-shipment-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 190px;

  img {
    width: 30px;
  }
}

.search-bar {
  margin: 10px 10px 10px 0px;

  .v-btn {
    height: 50px;
    border-radius: 10px;
  }

  .v-text-field {
    position: relative;
    right: -25px;
    height: 40px;
    display: inline-block;
    border-radius: 10px;
  }

  .text-input-blue .v-text-field__slot input {
    color: #00f !important;
  }
}

.v-main {
  width: 100% !important;
  background-color: #faf6ff;
}

@media (max-width: 768px) {
  .v-main {
    padding: 10px 0px !important;
  }
  .nav-bar,
  .menu-bar {
    display: none;
  }

  .mobile-nav {
    width: 100vw;
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 999;
    padding: 0px !important;

    .mobile-nav-list-item {
      text-align: center;
    }
  }

  .v-list-item:nth-child(5) {
    position: inherit;
  }

  .mobile-nav .mobile-nav-list-item[data-v-314f53c6] {
    justify-content: center;
  }
}
</style>
