<template>
  <div id="ecom">
    <homePage />
  </div>
</template>

<script>
import homePage from "@/components/stall/home/homePage.vue";
export default {
  name: "index",
  components: {
    homePage,
  },
};
</script>

<style lang="scss" scoped>
#ecom {
  background-color: #f6f8fd;
}
</style>
