<template>
  <div>
    <detailsMenu />
    <!--Navbar-->
    <div class="navbar">
      <div class="logo">
        <a href="/">
          <h2>Super Save Lanka (Pvt) Ltd</h2>
        </a>
      </div>
      <div class="items">
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contactus">Contact</a></li>
          <!-- <li><a href="/login" class="login-btn"><v-btn width="120" color="#8600D9" class="white--text">Login</v-btn></a></li> -->
        </ul>
      </div>
    </div>
    <div class="nav-toggle-div">
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
        class="nav-icon-toggle"
      ></v-app-bar-nav-icon>
      <v-navigation-drawer v-model="drawer" absolute temporary width="80%">
        <v-list nav align="center">
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>
                <a href="/" class="resp-links">Home</a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <a href="#about/" class="resp-links">About</a>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <a href="#contact" class="resp-links">Contact</a>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <router-view></router-view>

    <div class="footer-credits">
      Made With 💖 By
      <a href="https://www.vantageflow.in/" target="_blank">vantageflow.in</a>
    </div>
  </div>
</template>

<script>
import detailsMenu from "../components/home/details.vue";
export default {
  name: "navbar",
  components: {
    detailsMenu,
  },
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@500&family=Poppins&display=swap");

.footer-credits {
  background-color: #070021;
  text-align: center;
  color: #ffffff;
  width: 100vw;
  padding: 5px 0px;
}
* {
  font-family: "IBM Plex Sans Arabic", sans-serif;
}

a {
  text-decoration: none;
  color: #fff;
}

.items > ul > li > a,
.resp-links {
  transition: all 0.4s;
  color: #fff;
}

.items > ul > li > a:hover,
.resp-links:hover {
  border-bottom: 1px solid gold;
}

.nav-toggle-div {
  display: none;
}

.v-list {
  transform: translateY(150px);
}

.resp-links {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

.logo {
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 7px;
  //   height:6vh;

  h2 {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: blue;
    background: -webkit-linear-gradient(45deg, red, purple);
    background-clip: text;
    font-weight: bolder;
    -webkit-text-fill-color: transparent;
    padding: 0px;
  }

  a {
    text-decoration: none;
  }
}

.nav-icon-toggle {
  visibility: hidden;
}

.navbar {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  background: -webkit-linear-gradient(
    45deg,
    rgb(255, 199, 45),
    rgb(202, 131, 0)
  );
  color: white;
}

.items ul {
  display: flex;
  list-style: none;
  margin-right: 40px;
  text-align: center;
  float: right;
  padding: 12px;

  li a {
    text-decoration: none;
    margin: 0 0 75px 75px;
    font-size: 18px;
    letter-spacing: 1px;
    padding-top: 8px;
    color: white;
  }
}

@media (max-width: 788px) {
  .navbar {
    height: 8vh !important;
    padding: 0px 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .items ul {
    display: none;
  }

  .nav-icon-toggle {
    position: absolute;
    visibility: visible;
    width: 50px;
    right: 15px;
    top: 10px;
    color: white;
  }

  .nav-toggle-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>