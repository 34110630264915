<template>
  <div class="section5">
      <h4>Now we can provide Return labels for your item</h4>
      <logo-slider>
          <div>
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" alt="">
              <img src="https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg" alt="">
              <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Etsy_logo.svg" alt="">
              <img src="https://caseescape.com/wp-content/uploads/2014/07/bonanza-logo.jpg" alt="">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Wish_logo.svg" alt="">
              <img src="https://www.knowyourmobile.com/wp-content/uploads/2020/11/Payoneer-Logo.jpg" alt="">
              <img src="https://learning-tribes.com/wp-content/uploads/2021/01/paypal-logo-png-transparent-1280x416.png" alt="">
          </div>
          <div>
              <img src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" alt="">
              <img src="https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg" alt="">
              <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Etsy_logo.svg" alt="">
              <img src="https://caseescape.com/wp-content/uploads/2014/07/bonanza-logo.jpg" alt="">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Wish_logo.svg" alt="">
              <img src="https://www.knowyourmobile.com/wp-content/uploads/2020/11/Payoneer-Logo.jpg" alt="">
              <img src="https://learning-tribes.com/wp-content/uploads/2021/01/paypal-logo-png-transparent-1280x416.png" alt="">
          </div>
      </logo-slider>
  </div>
</template>

<script>
export default {
    name:'sectionFive'
}
</script>

<style lang="scss" scoped>
    .section5 
    {
        height: 40vh; 
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
       h4{
            text-align: center; 
            font-family: 'Poppins',sans-serif;
            color: #7F7F7F;
            font-size:30px;
       }
    }
    .logos 
    {
        width:100%;
        height: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        img 
        {
            width:100px;
            margin-left:8px;
            margin-right:8px;
        }
    }

    logo-slider {
  --image-size: 60px;
  padding: 50px;
  overflow: hidden;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
logo-slider:hover div {
  animation-play-state: paused;
}
logo-slider div {
  display: flex;
  position: relative;
  animation: marquee 20s linear infinite;
  justify-content: space-around;
}
logo-slider img {
  display: block;
  min-width: var(--image-size);
  height: var(--image-size);
  margin: 0 1vw;
}
logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 80px, rgba(255, 255, 255, 0) calc(100% - 80px), white);
}
@media (max-width: 900px) {
  logo-slider {
    --image-size: 50px;
    --image-size: min(max(50px, 10vw), 100px);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

    @media (max-width:780px) {
        h4{
            font-size:20px !important;
        }
        .logos 
        {
        //     display: grid; 
        //    grid-template-columns: repeat(2,1fr); 
           justify-content: center; 
           align-items:center; 
           img:nth-child(7) 
           {
                margin-left: auto;
                margin-right: auto; 
                display: block;
           }
        }
    }
</style>