import {
    db,
    auth
} from "../firebase";
import firebase from "firebase";

export const cartService = {
    get,
    add,
    // update,
};

//  ANCHOR[id=userCart]  
const userCart = []
async function get() {
    return await db.collection("cart").doc(auth.currentUser.uid).get().then((querySnapshot) => {
            querySnapshot.data().all.forEach(cartItem => {
                userCart.push(cartItem)
            });
            return querySnapshot.data().all
        })
        .catch((error) => {
            console.error("error", error);
            return error
        })
}


async function add(pId) {
    // Calling Get function will store all the cart items in local array userCart LINK src/services/cart.service.js#userCart
    await get()
    // Finding the product in the array 
    let product = userCart.find(cartItems => cartItems.productId === pId)
    // if the product exist in the cart all array, the quantity will be updated by 1
    if (product) {
        // Finding the product in the Local cart array and updating the quantity by 1
        userCart.forEach(cartItem => { 
            if (cartItem.productId === pId) {
                cartItem.productQuantity++;
            }
        })
        // updating the cart in the firebase with the local array userCart
        await db.collection("cart").doc(auth.currentUser.uid).update({
            all: userCart
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
    }
    // if the product doesn't exist in the cart all array, new array object will be added with productId and quantity one
    else {
        await db.collection("cart").doc(auth.currentUser.uid).update({
            all: firebase.firestore.FieldValue.arrayUnion({
                productId: pId,
                productQuantity: 1
            })
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
    }
}

/* NOTE To test the cart service, hit the following URL: http://localhost:8080/product/{productId}*/

// Cart Update function 
// async function update(pId) {
// const newProduct = [{ productId: pId, productQuantity: 1,},{ productId: 234234, productQuantity: 2,}]
//     return await db.collection("cart").doc(auth.currentUser.uid).update({
// all: newProduct
//     })
// }