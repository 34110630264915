import { functions } from "../firebase";
export const authService = {
    registerRequest,
    register,
};


async function registerRequest(emial, password) {
    let result = {
        success: false,
        msg: "",
        code: 0,
    }

    const registerRequestCF = functions.httpsCallable('registerRequest');
    await registerRequestCF({ email: emial, password: password })
        .then((response) => {
            console.log("recived response from sendOtpCF", response);
            // Read response of the Cloud Function.
            let data = response.data;
            result.success = data.success;
            result.msg = data.msg;
            result.code = data.code;
        })
        .catch((err) => {
            result.success = false;
            result.msg = "Failed to send OTP";
            result.code = 0;
            console.error("Error while sending data", err.code, err.message);
        })


    return result
}

async function register(email, otp) {
    let result = {
        success: false,
        msg: "",
        code: 0,
    }

    const registerCF = functions.httpsCallable('register');
    await registerCF({ email: email, otp: otp })
        .then((response) => {
            console.log("recived response from sendOtpCF", response);
            // Read response of the Cloud Function.
            let data = response.data;
            result.success = data.success;
            result.msg = data.msg;
            result.code = data.code;
        })
        .catch((err) => {
            result.success = false;
            result.msg = "Failed to send OTP";
            result.code = 0;
            console.error("Error while sending data", err.code, err.message);
        })
    return result
}

