<template>
  <div class="section3">
    <div class="div1">
      <h2>Why us?</h2>
      <h3>Solutions for E-Commerce and Logistics</h3>
      <p>
        Colombo mail is your e-commerce logistics specialist, connecting sellers
        to buyers across the globe. We provide an online platform for local and
        international fulfillment for eCommerce companies of all sizes. With
        access to global marketplaces and easy delivery fulfillment, Colom
      </p>
    </div>
    <div class="div2">
      <img src="../../../assets/main/home/3.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "sectionThree",
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}
.section3 {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: auto;
  background: linear-gradient(45deg, #edf1f4, white);
  .div1 {
    padding: 30% 18%;
    h2 {
      font-size: 35px;
      font-weight: 400;
      color: #181818;
    }
    h3 {
      font-size: 25px;
      color: #2b2b2b;
      font-weight: 400;
    }
    p {
      color: #1d1d1d;
      font-weight: 400;
      font-size: 16px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  .div2 {
    padding: 10%;
    img {
      width: 90%;
      height: 100%;
    }
  }
}
@media (max-width: 780px) {
  .section3 {
    display: flex;
    flex-direction: column-reverse;
    .div1 {
      padding: 10% 12%;
      h2 {
        font-size: 25px !important;
      }
      h3 {
        font-size: 18px !important;
      }
      p {
        font-size: 14px !important;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>