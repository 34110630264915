<template>
  <main>
    <!-- progress-color="amber" -->
    <v-carousel
      cycle
      height="400"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <div class="baner">
          <div class="right">
            <h1>{{ item.title }}</h1>
            <p>{{ item.content }}</p>
            <v-btn color="error" dark large>{{ item.button }}</v-btn>
          </div>
          <div class="left">
            <img src="@/assets/stall/shoe.jpg" alt="" />
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </main>
</template>

<script>
export default {
  name: "carousel",
  data() {
    return {
      items: [
        {
          title: "50% OFF For Your first Shooping",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit Consequatur, odio",
          button: "Shop now",
          // src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          title: "Super good product are there have it of offer here",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit Consequatur, odio",
          button: "Click Here",
          // src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          title: "welcome to grap shop",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit Consequatur, odio",
          button: "Read more",
          src: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
// * {
//   margin: 0 !important;
//   padding: 0;
//   box-sizing: border-box;
// }
.baner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  box-shadow: grey;
}
.right {
  width: 60%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  p {
    width: 65%;
    margin-top: 30px;
  }
  .v-btn {
    margin-top: 40px;
  }
}
.left {
  width: 40%;
  img {
    width: 80%;
  }
}
@media (max-width: 780px) {
  .baner {
    width: 100%;
  }
  .right {
    h1 {
      font-size: 1.2rem;
    }
    p {
      width: 90%;
      font-size: 1rem;
    }
  }
  .left {
    // width: 50%;
    img {
      width: 100%;
    }
  }
}
</style>
