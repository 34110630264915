export const searchHelper = {
    checkValidKeyword,
};

function checkValidKeyword(rawKeyword) {
    console.log("checkValidKeyword", rawKeyword)
    const response = {
        keywordSuccess: false,
        keywordMsg: null,
        keywordType: -1,
        keyword: rawKeyword
    }

    //ERROR CHECK
    //Min Length Check
    if (rawKeyword.length != 8) {
        response.keywordMsg = "Please enter at least 8 characters"
        return response
    }

    //check wether order ID
    const first3 = rawKeyword.slice(0, 3).toUpperCase();
    const last5S = rawKeyword.slice(3, 8)
    const last5 = parseInt(last5S);
    console.log("first3", first3, "last5", last5)

    if (rawKeyword.length == 8 && first3 == "CMB" && last5 < 99999) {
        response.keyword = rawKeyword;
        response.keywordMsg = "It is an Order ID"
        response.keywordSuccess = true
        response.keywordType = 0
    }


    // else its name
    else {
        response.keywordMsg = "Syntax Error"
    }


    return response

} 