<template>
  <div id="about" class="section4">
    <div class="div1">
      <img src="../../../assets/main/home/4.svg" alt="" />
    </div>
    <div class="div2">
      <h2>About us</h2>
      <p>
        Colombo mail is your e-commerce logistics specialist, connecting sellers
        to buyers across the globe. We provide an online platform for local and
        international fulfillment for eCommerce companies of all sizes. With
        access to global marketplaces and easy delivery fulfillment, Colom
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "sectionFour",
};
</script>

<style lang="scss" scoped>
img {
  pointer-events: none;
}
.section4 {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  background: linear-gradient(45deg, white, #edf1f4);
  height: auto;
  .div1 {
    padding: 20% 15%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .div2 {
    padding: 30% 18%;
    h2 {
      font-size: 35px;
      font-weight: 400;
      color: #2e2e2e;
    }
    p {
      font-size: 16px;
      letter-spacing: 1px;
      color: #1f1f1f;
      font-weight: 400;
    }
  }
}
/*Media Queries */
@media (max-width: 780px) {
  .section4 {
    display: flex;
    flex-direction: column;
  }
  .div1 {
    padding: 8% 10% !important;
  }
  .div2 {
    padding: 12% !important;
    h2 {
      font-size: 28px !important;
    }
    p {
      font-size: 14px !important;
    }
  }
}
</style>