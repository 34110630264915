<template>
  <contactUs />
</template>

<script>
import contactUs from "../shared/contactUs.vue";

export default {
  components: {
    contactUs,
  },
};
</script>

<style></style>
