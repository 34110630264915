import {
    db
} from "../firebase";

export const bulkCargoService = {
    getBulkdata
}

async function getBulkdata() {
    console.log('bulk data')
    return await db.collection("pages").doc("mailHome").get().then((querySnapshot) => {
        const mailHome = querySnapshot.data();
        console.log("bulkdata", mailHome)
        return mailHome;
    }).catch((error) => {
        return error;
    });
}