<template>
  <div>
    <v-app-bar fixed app class="px-12 elevation-0" color="purple darken-2 ">
      <!-- <v-toolbar-title class="white--text"> -->
      <!-- <router-link to="/stall" style="text-decoration: none"> -->
      <img
        src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2FColombo_Mall_Logo.png?alt=media&token=8ec8dce4-3603-4568-997c-cffd859334ad"
        alt="logo"
      />
      <!-- <b class="white--text">Colombo Stall</b> -->
      <!-- </router-link> -->
      <!-- </v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        solo
        clearable
        class="mt-6 ml-5 search"
        label="Search Stall...."
        append-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-btn
        @click.stop="cartDrawer = !cartDrawer"
        text
        class="white--text mr-7"
      >
        <v-icon dark class="mr-2"> mdi-cart </v-icon>
        Cart
      </v-btn>

      <v-menu open-on-hover offset-y :close-on-click="closeOnClick">
        <template v-slot:activator="{ on, attrs }">
          <v-icon dark class="mr-9" v-bind="attrs" v-on="on">
            mdi-account
          </v-icon>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index">
            <v-list-item-title :href="item.href" :key="item.href">
              <v-btn text>
                {{ item.title }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="cartDrawer" fixed right temporary>
      <!-- Cart Info -->
      <div class="cart-info">
        <div class="cart-info__left">
          <v-icon> mdi-shopping-outline </v-icon>
          <span>0 items</span>
        </div>

        <div class="cart-info__right">
          <v-icon @click.stop="cartDrawer = !cartDrawer"> mdi-close </v-icon>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item-group v-model="group">
        <!-- <div class="empty-cart">
            <v-img
              src="https://bonik-nuxt.vercel.app/_nuxt/img/empty-cart.0de7f76.png"
            >
            </v-img>
            <p>Your shopping bag is empty.Start Shipping.</p>
          </div> -->

        <v-list-item class="product">
          <div class="product-quantity-modifier">
            <v-icon @click="increment"> mdi-plus </v-icon>
            <span v-text="quantity"></span>
            <v-icon @click="decrement"> mdi-minus </v-icon>
          </div>

          <div class="product-img">
            <img
              src="https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MKUQ3_VW_34FR+watch-45-alum-midnight-nc-7s_VW_34FR_WF_CO?wid=750&hei=712&trim=1,0&fmt=p-jpg&qlt=95&.v=1632171067000,1631661671000"
            />
          </div>

          <div class="product-content">
            <h5 class="product-name">Watch</h5>
            <h6 class="product-quantity">$300 x {{ this.quantity }}</h6>
            <span class="product-price">$300</span>
          </div>
        </v-list-item>
        <!-- Note Check out Btns -->
        <div class="btn-checkout">
          <v-btn to="/checkout">Checkout Now ($28M)</v-btn>
        </div>
      </v-list-item-group>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "defaultLayout",
  data: () => ({
    cartDrawer: false,
    quantity: 1,
    items: [
      { title: "My Order", href: "/myOrder" },
      { title: "Colombo Mail", href: "/" },
      { title: "Settings", href: "/" },
      { title: "Log Out", href: "/" },
    ],
    closeOnClick: true,
  }),

  watch: {
    group() {
      this.cartDrawer = false;
    },
  },

  methods: {
    decrement() {
      this.quantity--;
    },
    increment() {
      this.quantity++;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 10%;
  // margin-top: 15px;
  // height: 100%;
}
.cart-info {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.9rem;
    margin-inline-start: 10px;
  }
}
.cart-drawer {
  width: 400px;
}

.empty-cart {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #c3c3c3;
}
.product-quantity-modifier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 5px;
  .v-icon {
    font-size: 1.5rem;
    border: 1px solid #e66fbe;
    border-radius: 50%;
    color: #e66fbe;
  }
  span {
    color: grey;
    font-size: 1.4rem;
  }
}
.product-img {
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.product-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .product-name {
    font-size: 1.3rem;
  }
  .product-price {
    font-size: 1.2rem;
    color: #e66fbe;
    margin-top: 15px;
  }
  .product-quantity {
    color: #757373;
  }
}
.v-list-item-group:nth-last-child(1) {
  padding-bottom: 70px;
}

.btn-checkout {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgb(255, 241, 247);
  padding: 20px;
  .v-btn {
    color: #fff;
    background: #e66fbe;
  }
}
</style>
