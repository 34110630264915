import { render, staticRenderFns } from "./sectionFive.vue?vue&type=template&id=1e97a354&scoped=true&"
import script from "./sectionFive.vue?vue&type=script&lang=js&"
export * from "./sectionFive.vue?vue&type=script&lang=js&"
import style0 from "./sectionFive.vue?vue&type=style&index=0&id=1e97a354&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e97a354",
  null
  
)

export default component.exports