import {
    db, auth
} from '../firebase';

export const searchService = {
    searchShipmentByOrder,
};

async function searchShipmentByOrder(orderId) {
    console.log("searchShipmentByOrder", orderId);
    const uid = auth.currentUser.uid;
    return await db.collection("mailShipments")
        .where("orderId", "==", orderId)
        .where('uid', '==', uid)
        .get().then(querySnapshot => {

            const shipments = processShipments(querySnapshot)
            console.log("shipments", shipments);

            return shipments
        })
        .catch(error => {
            console.error("error in firebse", error)
        })
}


function processShipments(rawShipments) {
    console.log("processShipments", rawShipments);
    let shipments = [];
    let sno = 0
    rawShipments.forEach(rawShipment => {
        const shipment = rawShipment.data();
        shipment.sno = ++sno;
        shipment.id = rawShipment.id;
        shipment.route = `myorders/${rawShipment.id}`;

        shipments.push(shipment);

    })
    console.log("shipments", shipments);
    return shipments
}