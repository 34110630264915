import { st, stR } from ".././firebase"

export const sharedService = {
  uploadFile
};

async function uploadFile(file, filePath) {

  await st
    .child(filePath)
    .put(file)
    .then(() => {
      console.log("Uploaded a file!");
    });

  return await stR
    .ref(filePath)
    .getDownloadURL()
    .then((downloadURL) => {
      console.log("File available at", downloadURL);
      return downloadURL
    });
}