<template>
  <div id="comingsoon">
    <div id="container-wrap">
      <div class="container">
        <div id="animation">
          <lottie-player
            src="https://assets10.lottiefiles.com/datafiles/AtGF4p7zA8LpP2R/data.json"
            background="transparent"
            speed="1"
            style="width: 20%; height: 20%"
            loop
            autoplay
          ></lottie-player>
        </div>
        <h1>www.colomboMall.lk</h1>
        <h3>Comming Soon</h3>
        <p>We will be celebrating the launch of our new site very soon!</p>
        <!-- <div class="btns"> -->
        <v-btn color="blue" text class="white--text" href="/">
          Go to Colombo Mail</v-btn
        >
        <!-- </div> -->
        <footer>
          <p>&#174; 2022 by colombomail.lk</p>
          <div class="icons">
            <v-icon medium> mdi-facebook </v-icon>
            <v-icon medium> mdi-twitter </v-icon>
            <v-icon medium> mdi-instagram </v-icon>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "comingSoon",
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,500&display=swap");

#comingsoon {
  background-color: #780091;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("https://cdn.pixabay.com/photo/2017/05/09/03/46/alberta-2297204_1280.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
#container-wrap {
  background: white;
  border-radius: 18px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  min-width: 300px;
  max-width: 700px;
  width: 70%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  font-family: "Poppins", sans-serif;
  text-align: center;
  //   margin-top: 120px;
  //   height: 100vh;
  background-color: transparent;
}
#animation {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-white {
  color: white;
}
h1 {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: blue;
  background: -webkit-linear-gradient(45deg, red, purple);
  background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
}
h3 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: blue;
  background: -webkit-linear-gradient(45deg, red, purple);
  background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
}
p {
  padding: 18px;
  font-size: 16px;
  color: rgb(45, 40, 82);
}

.v-icon {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  color: blue;
  background: -webkit-linear-gradient(45deg, red, purple);
  background-clip: text;
  font-weight: bolder;
  -webkit-text-fill-color: transparent;
}
.icons {
  padding-left: 15px;
}
</style>