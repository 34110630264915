<template>
  <div class="dropDown">
    <!-- <div>
      <sidebar />
    </div> -->
    <div class="sideBar">
      <ul>
        <li>
          <a href=""
            ><v-icon>mdi-human-female-female</v-icon> &nbsp; Fashion
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-laptop</v-icon> &nbsp;Electronics
            <v-icon class="ml-2"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-bike</v-icon> &nbsp;Bikes
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-home</v-icon> &nbsp;Home & Garden
            <v-icon class="ml-1"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-gift</v-icon> &nbsp;Gifts
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-music</v-icon> &nbsp;Music
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-human-male</v-icon> &nbsp;Health
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-dog</v-icon> &nbsp;Pets
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
        <li>
          <a href=""
            ><v-icon>mdi-baby-face</v-icon> &nbsp;Babys
            <v-icon class="ml-6"> mdi-chevron-right</v-icon></a
          >
        </li>
      </ul>
    </div>
    <div class="slideshow">
      <carousel />
      <flashDeal />
      <categories />
      <supersale />
      <support />
      <footerPart />
    </div>
  </div>
</template>

<script>
// import sidebar from "./sidebar.vue";
import carousel from "./carousel.vue";
import flashDeal from "./flashDeal.vue";
import categories from "./categories.vue";
import Support from "./support.vue";
import supersale from "./supersale.vue";
import footerPart from "./footerPart.vue";
export default {
  name: "homePage",
  components: {
    carousel,
    flashDeal,
    categories,
    Support,
    supersale,
    // sidebar,
    footerPart,
  },
};
</script>

<style lang="scss" scoped>
.dropDown {
  display: flex;
  justify-content: space-between;
}
.sideBar {
  z-index: 999999;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 20px 10px;
  width: 230px;
  margin-left: 30px;
  margin-top: 20px;
  border-radius: 7px;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  overflow: auto;
}
li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}
.slideshow {
  width: 80%;
  // background-color: #f6f8fd;
}
li a.active {
  background-color: #04aa6d;
  color: white;
}

li a:hover:not(.active) {
  // background-color: #d5475d;
  color: #d5475d;
}
@media (max-width: 780px) {
  .sideBar {
    display: none;
  }
  .slideshow {
    width: 100%;
  }
}
</style>
