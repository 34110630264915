import {
    searchService
} from "../../services";
import { searchHelper } from "../../helper/search.helper";

const state = () => ({
    searchResult: [],
    searchMsg: null,
    searchSuccess: false,
});

const getters = {
    searchResult: state => state.searchResult,
    searchMsg: state => state.searchMsg,
    searchSuccess: state => state.searchSuccess,
};

const actions = {
    async searchShipment({
        commit
    }, payLoad) {
        console.log("searchShipment", payLoad)
        const rawKeyword = payLoad.keyword




        //check valid keyword
        const result = searchHelper.checkValidKeyword(rawKeyword)
        console.log("checkValidKeyword", result);
        const validKW = result.keywordSuccess
        const keyword = result.keyword

        // if keyword is Order ID
        if (validKW)
            await searchService.searchShipmentByOrder(keyword).then(response => {
                console.log("searchShipmentByOrder", response);
                commit('setSearchResult', response);
                commit('setSearchMsg', "Got the result");
                commit('setSearchSuccess', true);
            }).catch(error => {
                commit('setSearchMsg', error);
                commit('setSearchSuccess', false);
            });
        return
    },
};

const mutations = {
    setSearchResult: (state, searchResult) => (state.searchResult = searchResult),
    setSearchMsg: (state, searchMsg) => (state.searchMsg = searchMsg),
    setSearchSuccess: (state, searchSuccess) => (state.searchSuccess = searchSuccess)
};

export default {
    state,
    getters,
    actions,
    mutations
}