<template>
  <main>
    <v-card class="card__receiver-info">
      <div class="card-title">
        <span>1 </span>
        <h5>Delivery Details</h5>
      </div>
      <v-form @submit.prevent="orderData">
        <!-- <div class="input-row">
          <div class="input-col">
            <v-text-field
              outlined
              dense
              label="First Name*"
              v-model="firstName"
              required
              prepend-inner-icon="mdi-account-box-outline"
            >
            </v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              dense
              label="Last Name*"
              v-model="lastName"
              required
              prepend-inner-icon="mdi-account-box-multiple-outline"
            >
            </v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              label="VAT/TAX*"
              required
              dense
              v-model="vat"
              prepend-inner-icon="mdi-cash-multiple"
            >
            </v-text-field>
          </div>
        </div>

        <div class="input-row">
          <div class="input-col">
            <v-text-field
              outlined
              dense
              label="Mobile (Optional)"
              prepend-inner-icon="mdi-phone"
              v-model="mobile"
            ></v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              dense
              label="Email (Optional)"
              v-model="email"
              prepend-inner-icon="mdi-email-outline"
            >
            </v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              dense
              label="Company (Optional)"
              v-model="company"
              prepend-inner-icon="mdi-domain"
            >
            </v-text-field>
          </div>
        </div>
        <div class="input-row">
          <div class="input-col">
            <v-text-field
              outlined
              dense
              label="Address Line 1*"
              required
              v-model="address1"
              width="70%"
              prepend-inner-icon="mdi-book-marker-outline"
            >
            </v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              label="Address Line 2*"
              required
              dense
              v-model="address2"
              prepend-inner-icon="mdi-book-marker-outline"
            >
            </v-text-field>
          </div>
        </div>

        <div class="input-row">
          <div class="input-col">
            <v-text-field
              outlined
              label="Address Line 3*"
              v-model="address3"
              required
              dense
              prepend-inner-icon="mdi-book-marker-outline"
            >
            </v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              dense
              v-model="address4"
              label="Address Line 4 (Optional)"
              prepend-inner-icon="mdi-book-marker-outline"
            >
            </v-text-field>
          </div>
        </div>
        <div class="input-row">
          <div class="input-col">
            <v-text-field
              outlined
              dense
              v-model="zipcode"
              label="Zip Code/Postal Code (Optional)"
              prepend-inner-icon="mdi-post-outline"
            >
            </v-text-field>
          </div>
          <div class="input-col">
            <v-text-field
              outlined
              dense
              v-model="city"
              label="City*"
              prepend-inner-icon="mdi-map-marker-outline "
            >
            </v-text-field>
          </div>
        </div> -->

        <v-container>
          <v-row>
            <v-text-field
              outlined
              dense
              label="First Name*"
              v-model="firstName"
              required
              height="48%"
              prepend-inner-icon="mdi-account-box-outline"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="Last Name*"
              v-model="lastName"
              required
              prepend-inner-icon="mdi-account-box-multiple-outline"
            ></v-text-field>
            <v-text-field
              outlined
              label="VAT/TAX*"
              required
              dense
              v-model="vat"
              prepend-inner-icon="mdi-cash-multiple"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              outlined
              dense
              label="Mobile (Optional)"
              prepend-inner-icon="mdi-phone"
              v-model="mobile"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              label="Email (Optional)"
              v-model="email"
              prepend-inner-icon="mdi-email-outline"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Company (Optional)"
              v-model="company"
              prepend-inner-icon="mdi-domain"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              outlined
              dense
              label="Address Line 1*"
              required
              v-model="address1"
              prepend-inner-icon="mdi-book-marker-outline"
            ></v-text-field>

            <v-text-field
              outlined
              label="Address Line 2*"
              required
              dense
              v-model="address2"
              prepend-inner-icon="mdi-book-marker-outline"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              outlined
              label="Address Line 3*"
              v-model="address3"
              required
              dense
              prepend-inner-icon="mdi-book-marker-outline"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              v-model="address4"
              label="Address Line 4 (Optional)"
              prepend-inner-icon="mdi-book-marker-outline"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              outlined
              dense
              v-model="zipcode"
              label="Zip Code/Postal Code (Optional)"
              prepend-inner-icon="mdi-post-outline"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              v-model="city"
              label="City*"
              prepend-inner-icon="mdi-map-marker-outline "
            ></v-text-field>
          </v-row>
        </v-container>

        <v-btn color="#d23f57" class="btn btn__next white--text"
          >Next <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-form>
      <!-- <div class="card-title">
        <span>2 </span>
        <h5>Payment Details</h5>
      </div>
      <v-container fluid class="payment-info">
        <v-radio-group v-model="radios">
          <template v-slot:label>
            <div>Your Preferred <strong>Pay Mode</strong></div>
          </template>
          <v-radio value="online">
            <template v-slot:label>
              <div>
                Online <strong class="success--text">UPI/Net Banking</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="bankTransfer">
            <template v-slot:label>
              <div>
                Bank
                <strong class="primary--text">Bank Account</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="bankDeposit">
            <template v-slot:label>
              <div>
                Bank
                <strong class="amber--text">Bank Deposit</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="cash">
            <template v-slot:label>
              <div>
                Cash <strong class="deep-orange--text">Cash Payment</strong>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-btn
          color="#d23f57"
          class="btn btn__submit white--text"
          @click="orderData"
          >Done <v-icon>mdi-check-circle-outline</v-icon>
        </v-btn>
      </v-container> -->
    </v-card>

    <!-- <div class="order-details">
      <div class="card-title">
        <span>3 </span>
        <h5>Order Details</h5>
      </div>
      <v-simple-table class="table__order-details">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in cartItems" :key="item.name">
              <td>{{ item.quantity }} x {{ item.name }}</td>
              <td>$ {{ item.price }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <ul>
        <li>Subtotal: <strong>$2806</strong></li>
        <li>Shipped: <strong>$2806</strong></li>
        <li>Tax: <strong>$2806</strong></li>
        <li>Discount: <strong>$2806</strong></li>
      </ul>
      <v-divider></v-divider>
      <div class="total">Total: <strong>$2806</strong></div>
    </div> -->
    <div class="card-2"></div>
    <v-card width="500">
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis
      praesentium earum reprehenderit culpa quaerat pariatur ab vitae iste
      nesciunt excepturi corrupti voluptatem consequatur officiis, quos, fuga
      provident quam veritatis dolor temporibus voluptates optio inventore
      quisquam! Dolor, tempora. Aspernatur distinctio obcaecati unde eveniet
      perferendis atque illum sed ipsum dignissimos deserunt quo a debitis,
      dolores minima aliquam repellat odit fugiat. Recusandae adipisci natus
      corrupti explicabo veniam saepe dicta autem totam excepturi, fuga tempore?
      Unde quam libero possimus, corporis placeat cumque ipsa! Totam ratione
      corrupti exercitationem voluptas iusto ducimus sapiente nostrum eveniet
      non labore tenetur, debitis beatae enim ab, laborum eaque perspiciatis
      ea.</v-card
    >
  </main>
</template>
<!-- ANCHOR[id=my-anchor]  Hey Wsaupp -->
<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      vat: "",
      mobile: "",
      email: "",
      company: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      zipcode: "",
      city: "",
      radios: "online",
      cartItems: [
        {
          name: "Zircon Stone",
          price: 159,
          quantity: 4,
        },
        {
          name: "Zircon Stone",
          price: 159,
          quantity: 4,
        },
        {
          name: "Zircon Stone",
          price: 159,
          quantity: 4,
        },
        {
          name: "Zircon Stone",
          price: 159,
          quantity: 4,
        },
        {
          name: "Zircon Stone",
          price: 159,
          quantity: 4,
        },
      ],
    };
  },
  methods: {
    orderData() {
      console.log("submitting...");
      const orderInfo = {
        firstName: this.firstName,
        lastName: this.lastName,
        vat: this.vat,
        mobile: this.mobile,
        email: this.email,
        company: this.company,
        address1: this.address1,
        address2: this.address2,
        address3: this.address3,
        address4: this.address4,
        zipcode: this.zipcode,
        cartItems: this.cartItems,
        radios: this.radios,
        city: this.city,
      };
      console.log(orderInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
  justify-content: space-between;
  min-height: 90vh;
  margin: 50px;
  color: FAF6FF;
  background: rgb(250, 246, 255);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 10px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.total {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.card__receiver-info {
  padding: 30px;
  width: 100%;
  max-width: 900px;
  margin-inline-end: 20px;
}

.order-details {
  width: 40%;
}

.card-title {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin: 10px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 40px;
    width: 40px;
    color: #fff;
    margin-inline-end: 10px;
    border-radius: 50%;
    background-color: #d23f57;
  }
}

.v-text-field {
  margin: 5px;
}

.v-form {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 100%;
  max-width: 150px;
  align-self: flex-end;
}

.btn__next {
  margin-inline-end: 20px;
}

.payment-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.v-card {
  .paymentCard {
    background-color: #fff;
    padding: 0px 40px 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
      0px 8px 24px rgba(24, 39, 75, 0.08);
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 800px) {
  main {
    flex-direction: column;
  }

  .card__receiver-info {
    width: 100%;
  }

  .order-details {
    width: 100%;
  }
  .card-2 {
    margin-top: 5px;
  }
}
</style>
