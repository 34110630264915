<template>
  <div id="bulkCargo">
    <staticNav />
    <div id="body">
      <h2 class="mb-3">Bulk Cargo</h2>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Country</th>
              <th class="text-left">Cost</th>
              <th class="text-left">Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in bulkCargos" :key="item.id">
              <td>{{ item.title }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.weight }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { bulkCargoService } from "../../services";
import staticNav from "@/layouts/staticNav.vue";

export default {
  name: "bulkCargo",
  created() {
    this.init();
  },
  components: {
    staticNav,
  },
  data() {
    return {
      bulkCargos: [],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
        },
        {
          name: "Eclair",
          calories: 262,
        },
        {
          name: "Cupcake",
          calories: 305,
        },
        {
          name: "Gingerbread",
          calories: 356,
        },
        {
          name: "Jelly bean",
          calories: 375,
        },
        {
          name: "Lollipop",
          calories: 392,
        },
        {
          name: "Honeycomb",
          calories: 408,
        },
        {
          name: "Donut",
          calories: 452,
        },
        {
          name: "KitKat",
          calories: 518,
        },
      ],
    };
  },
  methods: {
    async init() {
      const data = await bulkCargoService.getBulkdata();
      this.bulkCargos = data.bulkCargo;
    },
  },
};
</script>

<style>
#bulkCargo {
  height: 100vh;
}
#body {
  background-color: rgb(176, 91, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>