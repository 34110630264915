import {
  orderService
} from "../../services";


const state = () => ({
  singleOrders: [],
  allOrders: []
});

const getters = {
  allOrders: state => state.allOrders,
  singleOrders: state => state.singleOrders,
  newOrder: state => state.newOrder
};

const actions = {
  async fetchAllOrders({
    commit
  }) {
    // const userId = state.isAuthenticated
    // console.log("userId", userId)
    return await orderService.getAllOrders().then(res => {
      console.log("all orders in store", res);

      commit("setAllOrders", res);
    }).catch(err => {
      console.log(err);
      return err;
    });
  },

  async fetchOrder({
    commit
  }, orderId) {
    const order = await orderService.getOrder(orderId);
    commit("setOrder", order);
  },

  async fetchsingleOrder({
    commit
  }) {
    // const userId = state.isAuthenticated
    // console.log("userId", userId)
    return await orderService.getsOrder().then(res => {
      console.log("single orders ", res);

      commit("setAllOrders", res);
    }).catch(err => {
      console.log(err);
      return err;
    });
  },
};

const mutations = {
  setAllOrders(state, orders) {
    state.allOrders = orders;
  },
  setOrder(state, order) {
    state.singleOrders = order;
  },

};

export default {
  state,
  getters,
  actions,
  mutations
}