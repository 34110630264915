import {
    db,
    auth,
    functions
} from "../firebase";
import { sharedService } from "./shared.service"

export const userService = {
    // uploadProfile,
    getProfile,
    updateProfile,
    changePhone
}


async function getProfile() {
    console.log("getProfile called")
    const uid = auth.currentUser.uid
    console.log("UID", uid);


    return await db.collection("accounts").doc(uid).get().then((response) => {
        console.log(response.data())
        return response.data()
    })
}

async function updateProfile(profile, poiF, poiB) {
    console.log("getProfile called")

    const uid = auth.currentUser.uid
    await db.collection("accounts").doc(uid).update({
        ...profile
    })

    if ((poiF != null && poiF != undefined) && (profile.poi == 'Driving License' || profile.poi == 'Passport' || profile.poi == 'National Identity Card')) {
        await setPOI(auth.currentUser.uid, poiF, 0)
    }
    if (poiB != null && poiB != undefined && profile.poi == 'National Identity Card') {
        await setPOI(auth.currentUser.uid, poiB, 1)
    }
}

async function setPOI(docId, poi, frontOrBack) {
    //upload files
    let poiUrl = null;
    let poiFilePath = `POI/${docId}F`;

    if (frontOrBack == 1)
        poiFilePath = `POI/${docId}B`

    await sharedService.uploadFile(poi, poiFilePath).then((downloadurl) => {
        poiUrl = downloadurl
    })

    //add link to db
    if (frontOrBack == 0)
        await db.collection('accounts').doc(docId).update({
            poiFUrl: poiUrl,
            poiStatus: {
                title: 'Waiting for approval',
                code: 0
            }
        })
    else if (frontOrBack == 1)
        await db.collection('accounts').doc(docId).update({
            poiBUrl: poiUrl,
            poiStatus: {
                title: 'Waiting for approval',
                code: 0
            }
        })

    return poiUrl
}


async function changePhone() {
    const changephoneCF = functions.httpsCallable('changePhone');
    await changephoneCF({})
        .then((response) => {
            console.log("Change Phone response", response);
        })
        .catch((err) => {
            console.error("Change Phone error", err.code, err.message);
        })
}