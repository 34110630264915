import {cartService} from '../../services/index'
const state = () => ({
  cart: [],
});

const getters = {
  allCart: (state) => state.cart,
};

const actions = {
  addProductToCart({ commit }, product) {
    commit("setProductCart", product);
  },

    async increaseQuantity({commit,getters},payLoad){

    const { productId,isIncrease} = payLoad;

    // Get the cart from store
    const cart = getters.allCart;

    // find the product in array with productId
    const product = cart.find(productId == cart.id);

    // find the index of the product
    const productIndex = cart.indexOf(product);

    // Modify quantity of the product 
    if(!isIncrease)
    cart[productIndex].quantity--;
    else
    cart[productIndex].quantity++;

    // Mutate in state
    commit("setCart",cart);

    // update the cart in database
    await cartService.update(cart);
},

  async deleteProduct({commit,getters},productId){
    // get the product in getters with respect to the productId
    const cart = getters.allCart;

    // delete the fetched product in state[cart]
    const newCart = cart.filter(product => product.id != productId );

    // Mutate in state
    commit("setCart",newCart);

    // Update the new state[cart] in database
    await cartService.update(cart);

  },
};

const mutations = {
  setProductCart(state, product) {
    state.cart.push(product);
  },
  setCart(state,cart){
    state.cart = cart;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
