<template>
  <main>
    <router-link to="/myOrders/addNew">
      <v-card class="addshipment">
        <div class="text">
          <h3>Add a new shipment</h3>
          <p>Fill out the quick form to create a shipment</p>
        </div>
        <img src="../../assets/static/dashBoard/plusBtn1.svg" alt="" />
      </v-card>
    </router-link>
  </main>
</template>

<script>
export default {
  name: "addShipmentCard",
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.addshipment {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 280px;
  width: 100%;
  margin: 40px 20px;
  width: 280px;
  border-radius: 20px;
  background: url("../../assets/static/dashBoard/addship.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff !important;
}

.text {
  display: flex;
  flex-direction: column;
  margin-top: 48%;
  width: 65%;
}
@media (max-width: 768px) {
  .addshipment {
    background-position: cover;
    background-size: 90% 90%;
    width: 230px;
    height: 230px;
    margin: 0;
  }
}
</style>
