<template>
  <div id="secTwo">
    <logo-slider>
      <div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fcolombo%20mail%20express.jpeg?alt=media&token=87388c15-7321-4891-904e-aa274161bacf"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fswiss%20post%20fully%20track.jpeg?alt=media&token=32c53332-995f-45e2-90e3-0b2ae25a7da9"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2fsingapore%20post%20smart.jpeg?alt=media&token=753a4198-889b-4dc6-895e-3c5c1673f80e"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fsingapore%20post%20simple.jpeg?alt=media&token=38c412b9-ac63-42bd-bfee-f560b5f90699"
          alt=""
        />
        <img src="@/assets/home/aramex-logo.png" alt="aramex post" />
        <img src="@/assets/home/australiaPost-logo.png" alt="australia post" />
        <img src="@/assets/home/CanadaPost-logo.png" alt="Canada post" />
        <img src="@/assets/home/dpd-logo.png" alt="Dpd post" />
        <img src="@/assets/home/EmiratesPost-logo.jpg" alt="Emirates post" />
        <img src="@/assets/home/fedEx-logo.png" alt="FedEx post" />
        <img src="@/assets/home/herms-logo.png" alt="Herms post" />
        <img src="@/assets/home/royalMail-logo.jpg" alt="RoyalMail post" />
        <img src="@/assets/home/skynet-logo.png" alt="SkyNet post" />
        <img src="@/assets/home/tntLogo.png" alt="TNT post" />
        <img src="@/assets/home/upsExpress-logo.png" alt="UPS express post" />
        <img src="@/assets/home/usp-logo.png" alt="USP express post" />
      </div>
      <div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fcolombo%20mail%20express.jpeg?alt=media&token=87388c15-7321-4891-904e-aa274161bacf"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fswiss%20post%20fully%20track.jpeg?alt=media&token=32c53332-995f-45e2-90e3-0b2ae25a7da9"
          alt=""
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fsingapore%20post%20smart.jpeg?alt=media&token=753a4198-889b-4dc6-895e-3c5c1673f80e"
          alt=""
        />
        <img src="@/assets/home/aramex-logo.png" alt="aramex post" />
        <img src="@/assets/home/australiaPost-logo.png" alt="australia post" />
        <img src="@/assets/home/CanadaPost-logo.png" alt="Canada post" />
        <img src="@/assets/home/dpd-logo.png" alt="Dpd post" />
        <img src="@/assets/home/EmiratesPost-logo.jpg" alt="Emirates post" />
        <img src="@/assets/home/fedEx-logo.png" alt="FedEx post" />
        <img src="@/assets/home/herms-logo.png" alt="Herms post" />
        <img src="@/assets/home/royalMail-logo.jpg" alt="RoyalMail post" />
        <img src="@/assets/home/skynet-logo.png" alt="SkyNet post" />
        <img src="@/assets/home/tntLogo.png" alt="TNT post" />
        <img src="@/assets/home/upsExpress-logo.png" alt="UPS express post" />
        <img src="@/assets/home/usp-logo.png" alt="USP express post" />
      </div>
    </logo-slider>
    <div id="contents">
      <div class="cards">
        <div class="card">
          <img src="@/assets/home/sectionTwo/sec2-card-img.svg" alt="" />
          <div class="card-content">
            <h2>STEP ONE</h2>
            <p>
              Click on Register button and enter required details to start your
              journey with us.
            </p>
          </div>
        </div>
        <div class="card card-2">
          <img src="@/assets/home/sectionTwo/sec2-card-img.svg" alt="" />
          <div class="card-content">
            <h2>STEP TWO</h2>
            <p>
              Upon successful registration, submit your POI for verification
              purposes.
            </p>
          </div>
        </div>
        <div class="card">
          <img src="@/assets/home/sectionTwo/sec2-card-img.svg" alt="" />
          <div class="card-content">
            <h2>STEP THREE</h2>
            <p>
              Create your shipment to the destination country with our
              user-friendly shipping form.
            </p>
          </div>
        </div>
      </div>
      <div class="aboutUs">
        <h2>COLOMBO MAIL</h2>
        <h3>ABOUT US</h3>
        <p>
          Colombo mail is your e-commerce logistics specialist, connecting
          sellers to buyers across the globe. We provide an online platform for
          local and international fulfillment for eCommerce companies of all
          sizes. With access to global marketplaces and easy delivery
          fulfillment, Colombo mail lets focus on growing your business with
          lower your shipping costs.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sectionTwoHome",
};
</script>

<style lang="scss" scoped>
#secTwo {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 40px 0px;

  img {
    width: 160px;
    border-radius: 7px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 5px 18px;
  }
}

#contents {
  display: flex;
  justify-content: space-between;
  line-height: 1.8;

  h2 {
    font-size: 1.6rem;
    font-weight: 500;
    color: #08026c;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 500;
    color: #040144;
  }

  p {
    font-size: 1.05em;
    font-weight: 500;
    color: #040144;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: flex-start;

  .card:nth-child(2) {
    align-self: flex-end;
  }

  .card {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(7, 2, 108, 0.13);
    border-radius: 10px;
    margin-block: 20px;
    width: 100%;
    max-width: 550px;
    min-width: 200px;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .card-content {
      width: 60%;
      padding: 10px 10px;
    }

    img {
      width: 40%;
      margin-inline: 5px;
    }
  }
}

.aboutUs {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: start;

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #01001d;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #01001b;
  }

  p {
    font-size: 1.05em;
    font-weight: 600;
    color: #01001a;
  }
}

logo-slider {
  --image-size: 100px;
  padding: 50px;
  overflow: hidden;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

logo-slider:hover div {
  animation-play-state: paused;
}

logo-slider div {
  display: flex;
  position: relative;
  animation: marquee 20s linear infinite;
  justify-content: space-around;
}

logo-slider img {
  display: block;
  min-width: var(--image-size);
  height: var(--image-size);
  margin: 0 1vw;
}

logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    to right,
    white,
    rgba(255, 255, 255, 0) 80px,
    rgba(255, 255, 255, 0) calc(100% - 80px),
    white
  );
}

@media (max-width: 900px) {
  logo-slider {
    --image-size: 50px;
    --image-size: min(max(50px, 10vw), 100px);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

// *Media querry
@media (max-width: 786px) {
  .display-icons {
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;

    img {
      margin-right: 10px;
      margin-top: 20px;
      width: 45%;
      max-width: 160px;
    }
  }

  #contents {
    h2 {
      font-size: 1rem;
    }

    flex-direction: column-reverse;

    .cards {
      width: 90%;

      .card:nth-child(2) {
        transform: translateX(20%);
      }

      .card {
        height: 170px;
        padding: 20px 0px;

        .card-content {
          width: 70%;
          padding: 10px 10px;
        }

        img {
          width: 30%;
        }
      }
    }

    .aboutUs {
      width: 100%;
      padding: 20px;

      p {
        font-size: 1.2em;
      }
    }
  }
}
</style>