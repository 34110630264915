<template>
  <div>
    <div id="hero">
      <v-carousel
        height="100vh"
        class="slider"
        :interval="2000"
        controls
        cycle
        hide-delimiters
        :show-arrows="false"
      >
        <v-carousel-item
          class="slider-img"
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>

      <section class="content">
        <div class="head">
          <div class="text">
            <h1>
              Focus on <span>growing your business</span> with
              <span>lower shipping</span> costs
            </h1>
            <p>
              E-Commerce logistics specialist, connecting sellers to buyers
              across the globe. We provide an online platform for local and
              international fulfillment for eCommerce companies of all sizes.
            </p>
            <div class="track">
              <v-text-field
                solo
                dense
                v-model="tracknumber"
                label="Tracking Number"
                required
                width="50"
                class="mr-5"
              >
              </v-text-field>

              <v-btn
                color="d-flex"
                type="submit"
                width="auto"
                @click="redirectToParcelApp"
                target="_blank"
              >
                <v-icon left> mdi-crosshairs</v-icon>
                Track
              </v-btn>
            </div>
          </div>
          <div class="text1">
            <calc></calc>
          </div>
        </div>
        <div class="train">
          <img
            src="@/assets/home/secOne/train.svg"
            alt="home section train--img"
          />
        </div>
        <div class="drone">
          <img
            id="alternate-reverse"
            src="@/assets/home/secOne/drone.svg"
            alt="home section drone--img"
          />
        </div>
      </section>
    </div>
    <div id="bulkcargo">
      <a href="/bulkcargo">
        <v-carousel
          height="100vh"
          class="sliderTwo"
          :interval="4000"
          controls
          cycle
          hide-delimiters
          show-arrows-on-hover
        >
          <!-- v-for="(item, i) in itemsTwo" :key="i" :src="item.src" -->
          <v-carousel-item
            v-for="(item, i) in mailHome.bulkCargo"
            :key="i"
            :src="item.img"
          >
            <div class="image-content">
              <div class="content-header">
                <h1 class="country-name">{{ item.title }}</h1>
                <!-- <img
                class="country-flag"
                :src="item.img"
                :alt="item.title"
              /> -->
              </div>
              <div class="content-footer">
                <p>
                  Min Package Weight:
                  <span class="weight-allowed">{{ item.weight }}</span>
                  <br />
                  <span class="toc">Terms and conditions Applied</span>
                </p>
                <div class="country-price-card">
                  <h1>Price Per Kg:</h1>
                  <h2>{{ item.price }}</h2>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </a>
    </div>
  </div>
</template>

<script>
import calc from "../home/calc/calc.vue";
import { bulkCargoService } from "../../services";

export default {
  name: "sectionOneHome",
  created() {
    this.init();
  },
  components: {
    calc,
  },
  data() {
    return {
      mailHome: null,
      // props: [
      //   "title",
      //   "weight",
      // "price",
      // 'img',

      // ],
      items: [
        {
          src: "https://www.paddleyourownkanoo.com/wp-content/uploads/2020/01/print-klm-cargo-open-nose-2.jpg",
        },
        {
          src: "https://pbs.twimg.com/media/EnJmbfiXcAAuhIz.jpg",
        },
        {
          src: "https://s1.1zoom.me/b5050/935/Hamburg_Germany_Marinas_Ships_Container_ship_518518_3840x2160.jpg",
        },
        {
          src: "https://pbs.twimg.com/media/ETliIJ_X0AYmpL1.jpg:large",
        },
      ],
      // itemsTwo: [
      //   {
      //     country: "United States",
      //     countryFlag:
      //       "https://seeklogo.com/images/U/usa-flag-logo-F080109EF3-seeklogo.com.png",
      //     src: "https://forevertravel.com.my/wp-content/uploads/2018/01/new-york-5.jpg",
      //     weightAllowed: "20kg",
      //     pricePerKg: "USD $2.50",
      //   },

      //   {
      //     country: "United Arab Emirates",
      //     countryFlag:
      //       "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/255px-Flag_of_the_United_Arab_Emirates.svg.png",
      //     src: "https://www.visitdubai.com/-/media/images/leisure/neighbourhood-pages/dubai-marina-and-jbr/dubai-neighbourhoods-getty.jpg",
      //     weightAllowed: "20kg",
      //     pricePerKg: "USD $2.50",
      //   },
      //   {
      //     country: "Canada",
      //     countryFlag:
      //       "https://upload.wikimedia.org/wikipedia/en/thumb/c/cf/Flag_of_Canada.svg/320px-Flag_of_Canada.svg.png",
      //     src: "https://www.state.gov/wp-content/uploads/2019/04/Canada-e1556023963820-2500x1406.jpg",
      //     weightAllowed: "20kg",
      //     pricePerKg: "USD $2.50",
      //   },
      //   {
      //     country: "Australia",
      //     countryFlag:
      //       "https://image.shutterstock.com/image-vector/australia-day-flag-260nw-1275896374.jpg",
      //     src: "https://imageio.forbes.com/specials-images/imageserve/525799012/0x0.jpg?format=jpg&width=1200&fit=bounds",
      //     weightAllowed: "20kg",
      //     pricePerKg: "USD $2.50",
      //   },
      // ],
    };
  },
  methods: {
    redirectToParcelApp() {
      window.open(`https://parcelsapp.com/en/tracking/${this.tracknumber}`);
    },
    async init() {
      console.log("running ");
      this.mailHome = await bulkCargoService.getBulkdata();
      console.log("suriya", this.mailHome);
    },
  },
};
</script>

<style scoped lang="scss">
#hero {
  font-family: "Roboto", sans-serif;
  height: 100vh;
  color: #fff;
  background: linear-gradient(250.61deg, #0c046c 4.47%, #bb4d55 103.2%);
  position: relative;
  z-index: 0;
  a {
    text-decoration: none;
    color: #fff !important;
  }
}

#bulkcargo {
  font-family: "Roboto", sans-serif;
  height: 120vh;
  color: #fff;
  position: relative;

  a {
    text-decoration: none;
    color: #fff !important;
  }
}

.content {
  padding-top: 18vh;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.head {
  display: flex;
  justify-content: space-around;
}

.text {
  width: 50%;
  // text-align: left;
  line-height: 1.6;
  z-index: 99999;

  .btn-register {
    color: #fff;
    background: #f4bf00;
    margin-block: 40px;
  }
  .track {
    display: flex;
    justify-content: space-between;
    width: 450px;
    height: 50px;
    z-index: 99999;

    .v-btn {
      background: #ffc700;
      color: #fff;
    }
  }
}

.text h1 {
  font-size: 2.2em;
  font-weight: 400;

  span {
    font-weight: 700;
    font-size: 1.4em;
    // color: #0C046C;
  }
}

.text p {
  font-size: 1rem;
}

.btn-regi {
  background-color: #0c046c;
}

// *train

.train {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 10vh;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  // opacity: 0.7;
  z-index: 9;

  img {
    position: absolute;
    left: -15%;
    width: 80%;
    min-width: 400px;
    max-width: 800px;
    top: 42vh;
  }
}

// *DRONE
.drone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 30vh;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  z-index: 9;

  img {
    position: absolute;
    left: 45%;
    top: 50%;
    width: 100%;
    min-width: 50px;
    max-width: 200px;
    height: auto;
    margin-top: 30vh;
  }
}

#alternate-reverse {
  animation: move-up 2s ease-in-out infinite alternate-reverse;
}

.animation {
  height: auto;
}

@keyframes move-up {
  0% {
    /*bottom: 0px;*/
    transform: translateY(0);
  }

  100% {
    /*bottom: 250px;*/
    transform: translateY(-50px);
  }
}

// Slider
.slider {
  height: 100vh;
  width: 100%;
  position: absolute;
  opacity: 0.4;
  z-index: 0;
}

.slider .slider-img {
  height: 100vh;
  width: 100vw;
}

.sliderTwo {
  position: absolute;
  bottom: 0;

  .image-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;

      .country-name {
        font-size: 4rem;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 4px 2px 2px rgb(0, 0, 0);
        text-align: center;
      }

      .country-flag {
        max-width: 80%;
        width: 250px;
        min-width: 150px;
        border-radius: 5px;
      }
    }

    .content-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;

      p {
        font-size: 2rem;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        backdrop-filter: blur(6px) saturate(117%);
        -webkit-backdrop-filter: blur(6px) saturate(117%);
        background-color: rgba(17, 25, 40, 0.32);
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.125);
        padding: 20px;
        .toc {
          font-weight: 400;
          font-size: 0.6em;
          color: rgb(255, 255, 255);
        }
        .weight-allowed {
          font-weight: 700;
          font-size: 1.8em;
          color: rgb(46, 255, 168);
        }
      }
      .country-price-card {
        text-align: center;
        backdrop-filter: blur(6px) saturate(117%);
        -webkit-backdrop-filter: blur(6px) saturate(117%);
        background-color: rgba(81, 84, 90, 0.507);
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.125);
        padding: 20px;
        h1 {
          font-weight: 700;
          font-size: 2rem;
          color: rgb(255, 255, 255);
          display: inline;
        }
        h2 {
          font-weight: 700;
          font-size: 1.8em;
          color: rgb(46, 255, 168);
          display: inline;
        }
      }
    }
  }
}

// NOTE Media Query
@media (max-width: 786px) {
  * {
    font-size: 10px;
  }

  section {
    margin-top: 25px;
  }

  .nav-item {
    display: none;
  }

  .content {
    height: 60vh;
    align-items: center;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-top: 40%;

    .text {
      margin-top: 50%;
      line-height: 1.5;
      width: 95%;
      align-items: center;
    }

    h1 {
      font-size: 1.2rem;
    }

    p {
      margin: 10px;
      font-size: 0.8rem;
    }

    .text1 {
      margin: 15px auto;
    }
  }

  .train {
    img {
      left: -60%;
      width: 100%;
      top: 120%;
      min-width: 450px;
      max-width: 750px;
      height: auto;
    }
  }

  .drone {
    img {
      width: 100%;
      top: 90%;
      left: 60%;
      max-width: 120px;
      min-width: 100px;
    }
  }

  // Slider 2
  .sliderTwo {
    .image-content {
      .content-header {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .content-footer {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  #form-section {
    margin-top: 40vh;
  }
}
</style>
