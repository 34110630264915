<template>
  <div>
    <div class="welcome">
      <p v-if="profile.firstname != null">
        Hello {{ profile.firstname }} , Welcome to Colombomail 👋
      </p>
      <p v-else>Welcome to Colombomail 👋</p>
    </div>
    <div class="main">
      <div id="temp-content">
        <div id="add-shipment">
          <DashBoardAddShipmentCard />
        </div>
        <DashBoardNoticeCard />
      </div>
      <!-- <div class="left">
      <div class="cards">
        <DashBoardAddShipmentCard />
        <DashBoardVisitColomboStall />
      </div>
      <DashBoardNoticeCard />
    </div> -->
      <!-- <div class="recommended-sec">
      <DashBoardRecommendedSec />
    </div> -->
      <div class="dialogBox">
        <v-dialog v-model="dialog" max-width="650">
          <DashBoardNoticeCard>
            <v-btn
              class="mx-2 error"
              small
              fab
              depressed
              @click="dialog = false"
            >
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </DashBoardNoticeCard>
        </v-dialog>
      </div>
    </div>
    <div class="timeLine">
      <template>
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
          >
            <v-card :color="item.color" dark>
              <v-card-title class="text-h6">{{ item.title }}</v-card-title>
              <v-card-text class="white text--primary">
                <p>{{ item.content }}</p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashBoardAddShipmentCard from "../../components/dashBoard/addShipmentCard.vue";
import DashBoardNoticeCard from "../../components/dashBoard/noticeCard.vue";
export default {
  name: "index",
  props: ["user"],
  // middleware: "routerGuard",
  components: {
    DashBoardAddShipmentCard,
    DashBoardNoticeCard,
  },
  data() {
    return {
      dialog: false,
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
          title: "STEP ONE",
          content:
            "Click on Register button and enter required details to start your journey with us.",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
          title: "STEP TWO",
          content:
            "Upon successful registration, submit your POI for verification purposes.",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
          title: "STEP THREE",
          content:
            "Create your shipment to the destination country with our user-friendly shipping form.",
        },
      ],
    };
  },

  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    init() {
      console.log("Enter Dashboard");
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeLine {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  p {
    font-size: 12px;
  }
}
.welcome {
  color: rgb(66, 66, 66);
  font-size: 1.2rem;
  margin-top: 20px;
  margin-left: 40px;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
}

#temp-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
  margin-right: 40px;
  margin-left: 20px;
}

.cards {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  align-items: center;
}

.recommended-sec {
  width: 50%;
  /* margin-left: 20px; */
}

.btnn:hover {
  color: #7814a6;
}
.v-dialog {
  box-shadow: none !important;
}
@media (max-width: 768px) {
  * {
    font-size: 10px;
  }
  .welcome p {
    font-size: 20px;
  }
  #temp-content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #add-shipment {
    margin-top: -40px;
    margin-bottom: 20px;
  }
  .left {
    width: 90%;
  }

  .cards {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 90vh;
  }

  .main {
    margin-top: 40px;
    flex-direction: column;
  }

  .recommended-sec {
    width: 100%;
    display: flex;
    margin-block: 10px;
  }
}
</style>
