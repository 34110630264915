<template>
    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63380.39413787352!2d79.84429167910157!3d6.857650699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25b7978fdda5f%3A0xf59e6e8c9961b77d!2sColombo%20Mail!5e0!3m2!1sen!2sin!4v1634803333711!5m2!1sen!2sin" width="100%" height="600"  style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</template>

<script>
export default {
name: "map"
}
</script>

<style lang="scss" scoped>
.map{
    align-items:center;
    display: flex;
    justify-content: center;
    iframe{
        padding:0;
        margin:0;
    }

}
@media (max-width: 786px) {
    .map{
        width: 100%;
        // margin: 15px;
        // padding: 10px;
    }
}
</style>