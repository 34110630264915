<template>
  <div class="support">
    <div class="card">
      <v-avatar tile color="blue" size="50" class="rounded">
        <v-icon dark>mdi-van-utility</v-icon>
      </v-avatar>
      <h3>World wide Delivery</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid,
        similique.
      </p>
    </div>
    <div class="card">
      <v-avatar tile color="blue" size="50" class="rounded">
        <v-icon dark>mdi-credit-card-check</v-icon>
      </v-avatar>
      <h3>Safe payment</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid,
        similique.
      </p>
    </div>
    <div class="card">
      <v-avatar tile color="blue" size="50" class="rounded">
        <v-icon dark>mdi-headphones</v-icon>
      </v-avatar>
      <h3>24 / 7 support</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid,
        similique.
      </p>
    </div>
    <!-- <div class="card">
      <v-avatar tile color="blue" size="50" class="rounded">
        <v-icon dark>mdi-van-utility</v-icon>
      </v-avatar>
      <h3>World wide Delivary</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid,
        similique.
      </p>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  margin-top: 40px;
}
.card {
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 250px;
  height: 250px;
  text-align: center;
  //   margin-top: 20px;
  align-items: center;
  .v-avatar {
    margin-top: 20px;
  }
  h3 {
    margin-top: 30px;
  }
  p {
    color: grey;
    margin-top: 10px;
  }
}
@media (max-width: 780px) {
  .support {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0px;
  }
  .card {
    // padding: 30px;
    margin-top: 20px;
  }
}
</style>
