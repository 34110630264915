import Vue from 'vue';
import VueRouter from 'vue-router';
//store
import store from '../store';
//auth
import { auth } from './../firebase';

//error pages
// import p404 from "../views/error/error.vue";

//main pages
import home from '../views/main/home.vue';
import comingSoon from '../views/comingSoon.vue';

//layouts
import defaultLayout from './../layouts/default.vue';
import authLayout from './../layouts/authlay.vue';
import main from './../layouts/main.vue';
import stallLayout from './../layouts/stall.vue';

//dashboard routes
import dashboard from './../views/dashboard/index.vue';
import colomboMail from '../views/index.vue';

//mail static routes
import bulkCargo from "./../views/mail/bulkCargo.vue"

//STALL ROUTES

// stall
import stall from '../views/stall/Home/index.vue';
import allOrders from '../views/stall/orders/all';

// CheckOut
import checkout from '../views/stall/checkout/index.vue';

//orders
// import myOrder from "../views/stall/orders/myOrder";

Vue.use(VueRouter);

const routes = [
  //COMMON PAGES ROUTES
  {
    path: '/success/:orderId/:shipmentDocId',
    component: () => import('../views/payments/success.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/failed/:orderId/:shipmentDocId',
    component: () => import('../views/payments/failed.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  //STALL PUBLIC ROUTES
  {
    path: '/stall/',
    component: stallLayout,
    redirect: '/stall',
    meta: {
      isPublic: true,
    },
    children: [
      {
        path: '/stall',
        component: stall,
        name: 'stall',
      },
      {
        path: '/checkout',
        component: checkout,
        name: 'checkout',
      },

      {
        path: '/product/:id',
        component: () => import('../views/stall/product'),
        name: 'product',
      },
    ],
  },

  //STALL PRIVATE ROUTES
  {
    path: '/myOrder',
    component: stallLayout,
    redirect: '/myOrder',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/myOrder',
        component: () => import('../views/stall/orders/myOrder'),
        name: 'myOrder',
      },
      {
        path: '/category',
        component: () => import('../views/stall/category/searchCategory'),
        name: 'searchCategory',
      },
      {
        path: '/Orders',
        component: allOrders,
        name: 'Orders',
      },
    ],
  },

  //static routes
  {
    path: '/',
    component: main,
    redirect: '/',
    meta: {
      isPublic: true,
    },
    children: [
      {
        path: '/',
        component: home,
        name: 'home',
      },

    ],
  },

  //mail home page
  {
    path: '/colomboMail',
    component: colomboMail,
    name: 'colomboMail',
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/bulkcargo',
    component: bulkCargo,
    name: 'bulkCargo',
    meta: {
      isPublic: true,
    },
  },
  //public calculation page
  {
    path: '/calculateInfo',
    component: () => import('../views/calculateInfo/id.vue'),
    name: 'calculateInfo-id',
    meta: {
      isPublic: true,
    },
  },
  //stall comingsoon page
  {
    path: '/comingsoon',
    component: comingSoon,
    name: 'comingSoon',
    meta: {
      isPublic: true,
    },
  },

  //DASHBOARD ROUTES
  {
    path: '/dashboard',
    component: defaultLayout,
    redirect: '/dashboard',
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        path: '/dashboard',
        component: dashboard,
        name: 'dashboard',
      },
      {
        path: '/calculator',
        component: () => import('../views/calculator'),
        name: 'calculator',
      },
      {
        path: '/myAccount',
        component: () => import('../views/myAccount'),
        name: 'myAccount',
      },
      {
        path: '/myOrders',
        component: () => import('../views/myOrders'),
        name: 'myOrders',
      },

      {
        path: '/myOrders/addNew',
        component: () => import('../views/myOrders/addNew'),
        name: 'myOrders-addNew',
      },
      {
        path: '/myOrders/:id',
        component: () => import('../views/myOrders/_id'),
        name: 'myOrders-id',
      },
      {
        path: '/stall/:id',
        component: () => import('../views/stall/_id'),
        name: 'stall-id',
      },
    ],
  },

  //AUTH ROUTES
  {
    path: '/login',
    component: authLayout,
    redirect: '/login',
    meta: {
      isPublic: true,
      isAuth: true,
    },
    children: [
      {
        path: '/forgotpassword',
        component: () => import('../views/auth/forgotpassword.vue'),
        name: 'auth-forgotpassword',
      },
      {
        path: '/login',
        component: () => import('../views/auth/login.vue'),
        name: 'login',
      },
      {
        path: '/register',
        component: () => import('../views/auth/register'),
        name: 'register',
      },
    ],
  },
  {
    path: '/logout',
    component: () => import('../views/auth/logout.vue'),
    meta: {
      isLogout: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;
  const isPublic = to.matched.some((record) => record.meta.isPublic);
  const isAuth = to.matched.some((record) => record.meta.isAuth);
  const isLogout = to.matched.some((record) => record.meta.isLogout);
  console.log('auth.currentUser', auth.currentUser);
  console.log('isauthenticated', isAuthenticated);
  console.log('isPublic', isPublic);
  console.log('isLogout', isLogout);

  //just allow if logout
  if (isLogout) next();

  // take to dashboard if authenticated and trying to vist register or login
  if (isPublic && isAuth && isAuthenticated) {
    next('/dashboard');
    return;
  }
  console.log(
    '%c came after Redirect to dashboard | Authenticated && auth ',
    'background: #fff; color: red'
  );

  if (isPublic) {
    next();
    return;
  }
  console.log('%c came after public check', 'background: #fff; color: red');

  //reditect to login if not authenticated
  if (requiresAuth && !isAuthenticated) {
    next('/login');
    return;
  }
  console.log(
    '%c came after not authenticated check',
    'background: #fff; color: red'
  );

  //fetch if profile in null
  if (store.getters.profile == null || store.getters.profile == undefined) {
    console.log('fetch profile called');
    await store.dispatch('fetchProfile');
  }
  console.log(
    '%c came after profile null check',
    'background: #fff; color: red',
    store.getters.profile
  );

  //send without min data check if to is myaccount
  if (to.name == 'myAccount') {
    next();
    return;
  }
  console.log(
    '%came after min data skip check',
    'background: #fff; color: red'
  );

  console.log('store.getters.profile', store.getters.profile);
  //check wether basics user details are available
  if (
    store.getters.profile.firstname == null ||
    store.getters.profile.firstname == undefined ||
    store.getters.profile.firstname == '' ||
    store.getters.profile.poiStatus.code == -1 ||
    store.getters.profile.poiStatus.code == 1
  ) {
    console.log('%c caught in min data check', 'background: #fff; color: red');
    alert('First, Set your profile details and verify phone number');
    next('/myaccount');
    return;
  } else {
    console.log('%came after min data check', 'background: #fff; color: red');

    if (store.getters.profile.phone) {
      if (
        store.getters.profile.phone.verified == null ||
        store.getters.profile.phone.verified == undefined ||
        store.getters.profile.phone.verified == ''
      ) {
        alert('First, Set your profile details');
        next('/myaccount');
        return;
      }
    } else {
      alert('First, Set your profile details');
      next('/myaccount');
      return;
    }
  }
  next();
  console.log('%came after all check', 'background: #fff; color: red');
});

export default router;
