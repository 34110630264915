<template>
  <v-footer padless rounded-xl>
    <v-card
      flat
      tile
      class="lighten-1 white--text text-center"
      color="#0F3561"
      justify="end"
    >
      <v-card-title>Colombo Stall</v-card-title>
      <v-card-text class="white--text">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo
        perferendis, quaerat facilis minima eum molestias optio recusandae
        adipisci voluptas accusantium voluptates itaque in necessitatibus neque
        excepturi iusto sunt eligendi alias?
      </v-card-text>
      <v-card-text>
        <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        Copyright ©
        {{ new Date().getFullYear() }} — <strong>Colombo Stall</strong>.All
        rights reserved
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "footerPart",
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style lang="scss" scoped></style>
