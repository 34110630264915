<template>
  <div class="homee">
       <v-dialog
    v-model="dialog"
    :width="dialogWidth"
    max-width="none"
    fullscreen-mobile
  >
    <v-card>
     <v-btn
            icon
            @click="dialog = false"
            x-small
            fab
            class="close-button mt-1 mr-1"
          >
            <v-icon class="close-button-icon">mdi-close</v-icon>
          </v-btn>
      <v-img :src="imageSrc" :contain="true" height="100%"></v-img>
      <!-- <button @click="mergeshipments">Click to merge</button> -->
    </v-card>
  </v-dialog>


    <!-- <div class="dialogBox">
      <v-dialog v-model="dialog" width="900">
        <div id="complete">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2 error button_pop"
              justify="end"
              small
              fab
              text
              @click="dialog = false"
            >
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </v-card-actions>
          <img
            class="img-pop"
            src="@/assets/static/dashBoard/pop3.jpeg"
            alt=""
          />
        </div>

      
      </v-dialog>
    </div> -->
    <staticNav />
    <sectionOne />
    <sec-two />
    <SecThree />
    <HomeMap />
    <section id="videos">
      <div id="video-player-title">
        <h2>Watch Videos To know More About Us</h2>
      </div>
      <div id="video-Players">
        <iframe
          class="videoPlayer"
          frameBorder="0"
          src="https://www.youtube.com/embed/82mD3IeY3S0"
        >
        </iframe>

        <iframe
          class="videoPlayer"
          frameBorder="0"
          src="https://www.youtube.com/embed/JuVc1ttPreQ"
        >
        </iframe>
        <iframe
          class="videoPlayer"
          frameBorder="0"
          src="https://www.youtube.com/embed/samoqLotSX8"
        >
        </iframe>
      </div>
    </section>
    <HomeContactUs />
    <!-- <div class="logo-container">
        <a href="https://www.vote.bestweb.lk/site/www_colombomail_lk">
            <img src="https://www.bw2024.lk/vote4us.png" alt="logo" width="150" height="150">
        </a>
    </div> -->

  </div>
</template>

<script>
import staticNav from "@/layouts/staticNav.vue";
import sectionOne from "../components/home/sectionOne.vue";
import SecTwo from "../components/home/secTwo.vue";
import SecThree from "../components/home/secThree.vue";
import HomeMap from "../components/home/map.vue";
import HomeContactUs from "../components/home/contactUs.vue";
// import popNotice from "@/components/dashBoard/popNotice.vue";

import { mapGetters } from "vuex";
// import { calculatorService } from "../services";

export default {
  name: "Home",
  data() {
    return {
      dialog: false,
      dialogWidth: 800,
      imageSrc: 'https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/staticImages%2FnewServiceTodubai.jpg?alt=media&token=1dc1f32f-1497-40db-83e8-de6be27dd8ef',
      // imageSrc: 'https://www.bw2024.lk/vote4us.png',
    };
  },
  components: {
    sectionOne,
    SecTwo,
    SecThree,
    HomeMap,
    HomeContactUs,
    staticNav,
    // popNotice,
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["allOrders", "isAuthenticated"]),
  },
  mounted() {
    this.updateDialogWidth();
    window.addEventListener('resize', this.updateDialogWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDialogWidth);
  },
  methods: {
    init() {
      console.log("Enter Dashboard");
      this.dialog = true;
    
    },

    // mergeshipments(){
    //   calculatorService.getColomboExpressAndUpdateBulkAndExpress()
    // },

     updateDialogWidth() {
      if (window.innerWidth < 600) {
        this.dialogWidth = 300;
      } else {
        this.dialogWidth = 800;
      }
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}


.close-button {
  position: absolute;
  top: 0 ;
  right: 0 ;
  z-index: 2;
  background-color: red;
}



.dialogBox {
  z-index: 999;
}
.btn:hover {
  color: #7814a6;
}
.img-pop1 {
  max-width: 900px;
  /* max-width: 80vw; */
}

.dia{
  background-color: #FFFFD3;
}

.button_pop {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

/* .img-pop {
  width: 70vw;
  height: 70vh;
} */
.img2-pop {
  width: auto;
  max-width: 80vw;
}
* {
  font-family: "Roboto", sans-serif;
}
#videos {
  background-color: rgb(252, 246, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5vh 5vw;
}
#ideo-player-title {
  font-family: "Roboto", sans-serif;
  color: rgb(43, 0, 112);
  /* margin-bottom: 10vh; */
}
#video-Players {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 90vw;
  padding: 0px 10%;
  /* margin-top: 100vh; */
}
.videoPlayer {
  max-width: 300px;
  width: 30%;
  height: 165px;
  border-radius: 10px;
  margin-top: 20px;
}

.close-button-icon{
  color: white !important; 
}

@media only screen and (max-width: 600px) {
   .close-button {
  padding: 0px !important;
  margin: 0px !important;
  background: transparent !important;
}

.close-button-icon{
  color: red !important;
  /* background: transparent !important; */
  padding-left: 20px !important;
  padding-bottom: 10px !important;
}

  #video-player-title {
    text-align: center;
  }
  #video-Players {
    flex-direction: column;
  }
  .videoPlayer {
    width: 100%;
  }
  .img-pop {
    /* width: auto; */
    max-width: 510px;
    height: auto;
  }
  .dialogBox {
    width: 100%;
    z-index: 0;
  }

  .dia{
 max-width: 400px;
    z-index: 0;
  }

  .img-pop1{
    max-width: 400;
  }
  .complete {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #FFFFD3;
  }
}
</style>
