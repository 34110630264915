// import {
//     db
// } from "../firebase.js";
import { functions } from "../firebase";
import { sharedService } from "./shared.service"

export const paymentService = {
    payNow,
    submitProof,
};
// import axios from "axios";

async function payNow(rate, id, fname, lname, emailA, phone) {
    console.log("payNow service function running...");
    const NodeRSA = require('node-rsa');


    // const key = new NodeRSA('-----BEGIN PUBLIC KEY-----\n' +
    //     'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCl77TqWw7v6E4ocKPpkmI9+Q1X\n' +
    //     'rnx3bJbj2PuVhum13RdCZhi9g/BRlqLPploXPNFfUcSQlrpcREvRoLrQUfJWLsFk\n' +
    //     'QheQ2/g/tFETKDs8hIQ5KC9oD+sEefouE+lBX6ocwVW1G3OVDlbEq86pm2lP8nmO\n' +
    //     'S1hle8aXa8y+O5YmlQIDAQAB\n' +
    //     '-----END PUBLIC KEY-----');


    const key = new NodeRSA('-----BEGIN PUBLIC KEY-----\n' +
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBdYaRJkqS2wtM9GJD7INlNxKI\n' +
        '5v5AaVJ59weq1p1yCB+VfnKFh8JwZR8YVOdRt4NV6Th96RdkjJkcs3AreWUH56/4\n' +
        'Yfo/Xfbp985osd12RnGqN9rhnURRP/q1e2dXj4Zv8/mqzDProgo+pymI79sUR3Vi\n' +
        '+8moJACtpNqsIEh5RQIDAQAB\n' +
        '-----END PUBLIC KEY-----');
    let price = rate;
    let text = id + '|' + price;
    const encrypted = key.encrypt(text, 'base64');

    const data = {
        first_name: fname,
        last_name: lname,
        email: emailA,
        contact_number: phone,
        address_line_one: "India",
        process_currency: "LKR",
        secret_key: "7e3cde07-202c-4b7e-b4ff-738856025be3",
        // secret_key: "57cf5c8a-39c4-4610-9dbd-a3c7b9ab7eeb",
        custom_fields: "",
        payment: encrypted,
    }

    const gatewayUrl = "https://webxpay.com/index.php?route=checkout/billing"
    // const gatewayUrl = "http://stagingxpay.info/index.php?route=checkout/billing"

    console.log("Data", data);

    let form = document.createElement("form");
    form.setAttribute('method', "post");
    form.setAttribute('action', gatewayUrl);
    // form.setAttribute('target', "_blank");
    form.setAttribute("role", "form");
    form.setAttribute("hidden", true);

    let first_name = document.createElement("input");
    first_name.setAttribute('type', "text");
    first_name.setAttribute('name', "first_name");
    first_name.setAttribute('value', `${data.first_name}`);

    let last_name = document.createElement("input");
    last_name.setAttribute('type', "text");
    last_name.setAttribute('name', "last_name");
    last_name.setAttribute('value', `${data.last_name}`);

    let email = document.createElement("input");
    email.setAttribute('type', "text");
    email.setAttribute('name', "email");
    email.setAttribute('value', `${data.email}`);

    let contact_number = document.createElement("input");
    contact_number.setAttribute('type', "text");
    contact_number.setAttribute('name', "contact_number");
    contact_number.setAttribute('value', `${data.contact_number}`);

    let address_line_one = document.createElement("input");
    address_line_one.setAttribute('type', "text");
    address_line_one.setAttribute('name', "address_line_one");
    address_line_one.setAttribute('value', `${data.address_line_one}`);

    let process_currency = document.createElement("input");
    process_currency.setAttribute('type', "text");
    process_currency.setAttribute('name', "process_currency");
    process_currency.setAttribute('value', `${data.process_currency}`);

    let secret_key = document.createElement("input");
    secret_key.setAttribute('type', "text");
    secret_key.setAttribute('name', "secret_key");
    secret_key.setAttribute('value', `${data.secret_key}`);

    let custom_fields = document.createElement("input");
    custom_fields.setAttribute('type', "text");
    custom_fields.setAttribute('name', "custom_fields");
    custom_fields.setAttribute('value', `${data.custom_fields}`);

    let payment = document.createElement("input");
    payment.setAttribute('type', "text");
    payment.setAttribute('name', "payment");
    payment.setAttribute('value', `${data.payment}`);

    var submit = document.createElement("input"); //input element, Submit button
    submit.setAttribute('type', "submit");
    submit.setAttribute('value', "Submit");

    form.appendChild(first_name);
    form.appendChild(last_name);
    form.appendChild(email);
    form.appendChild(contact_number);
    form.appendChild(address_line_one);
    form.appendChild(process_currency);
    form.appendChild(secret_key);
    form.appendChild(custom_fields);
    form.appendChild(payment);

    form.appendChild(submit);

    document.getElementsByTagName("div")[0].appendChild(form);

    form.submit()

}

async function submitProof(proof, shipmentId, orderId, shipmentNo, proofNo) {
    //upload files
    let proofUrl = null;
    let proofFilePath = `paymentProof/${orderId}/${shipmentNo}/${proofNo}`;

    await sharedService.uploadFile(proof, proofFilePath).then((downloadurl) => {
        proofUrl = downloadurl
    })

    const paymentProof = {
        url: proofUrl,
        shipmentId: shipmentId,
    }

    console.log("paymentProof", paymentProof);

    const storePaymentProofCF = functions.httpsCallable('storePaymentProof');
    await storePaymentProofCF({ ...paymentProof })
        .then((response) => {
            console.log("storePaymentProof response", response);
        })
        .catch((err) => {
            console.error("storePaymentProof error", err.code, err.message);
        })
}

