<template>
  <div>
    <div class="deals">
      <div>
        <h3>
          <v-icon color="red">mdi-flash</v-icon>
          Flash Deals
        </h3>
      </div>
      <div>
        <a href="/category">
          <p>View all<v-icon>mdi-menu-right</v-icon></p>
        </a>
      </div>
    </div>
    <div class="product">
      <!-- <product
        v-for="product in products"
        :product="product"
        :key="product.id"
      /> -->
      <div>
        <v-slide-group
          multiple
          class="pa-4"
          prev-icon="mdi-arrow-left-circle"
          next-icon="mdi-arrow-right-circle"
          show-arrows
        >
          <v-slide-item v-for="product in products" :key="product.id">
            <router-link to="/product/:id">
              <product :product="product"
            /></router-link>
            <!-- <cardProduct :product="product" /> -->
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  </div>
</template>

<script>
import product from "./product.vue";
// import product from "./product";
// import cardProduct from "./cardProduct.vue";

export default {
  name: "flashDeal",
  components: {
    product,
    // cardProduct,
  },
  data() {
    return {
      items: [{}],
      products: [
        {
          name: "suriya",
          price: 123,
          id: 1,
          quantity: 0,
        },
        {
          name: "hari",
          price: 1236,
          id: 2,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
        {
          name: "suriyaprakash",
          price: 367,
          id: 3,
          quantity: 0,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  margin: 0 !important;
  width: 90%;
  text-decoration: none;
}
.deals {
  // width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // justify-content: center;
  padding: 10px;
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #414141;
  }
  p {
    font-size: 15px;
    color: grey;
  }
  a {
    text-decoration: none;
  }
}
@media (max-width: 780px) {
  .product {
    width: 100%;
    height: 100%;
  }
  .v-slide-item {
    width: 100%;
    height: 100%;
  }
}
</style>
