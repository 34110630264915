<template id="formsection2">
  <form data-app class="form" @submit.prevent="routerPush">
    <div class="fee-card">
      <h1>Fee Calculator</h1>
      <div class="input-field">
        <img
          class="input-logo mb-5"
          src="@/assets/home/secOne/mark.svg"
          alt=""
        />
        <v-autocomplete
          required
          solo
          dense
          v-model="selectedFrom"
          :items="from"
          label="From"
          req
        >
        </v-autocomplete>
      </div>
      <div class="input-field">
        <img
          class="input-logo mb-5"
          src="@/assets/home/secOne/to-icon.svg"
          alt=""
        />
        <v-autocomplete
          solo
          dense
          required
          v-model="selectedTo"
          req
          item-text="name"
          return-object
          :items="calcData.countries"
          label="To"
        >
        </v-autocomplete>
      </div>
      <div class="input-field">
        <img
          class="input-logo mb-5"
          src="@/assets/home/secOne/weight-icon.svg"
          alt=""
        />
        <v-text-field
          solo
          dense
          type="number"
          v-model="selectedWeight"
          label="Weight in Grams"
          required
          min="10"
          max="30000"
        >
        </v-text-field>
      </div>
      <span class="weight-index black--text">{{ this.calculateWeight }}</span>
      <v-btn
        color="d-flex"
        class="calculate-btn"
        height="50px"
        type="submit"
        width="auto"
      >
        <img class="mx-2" src="@/assets/home/secOne/button-icon.svg" alt="" />
        Calculate Now</v-btn
      >
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "calc",
  data() {
    return {
      from: ["SriLanka"],
      to: [],
      selectedFrom: null,
      selectedTo: null,
      selectedWeight: null,
      calculateWeight: null,
      fetched: false,
    };
  },
  computed: mapGetters(["calcData"]),
  watch: {
    selectedWeight(newValue) {
      if (newValue != null && newValue != undefined && newValue != "") {
        if (newValue < 1000) {
          this.calculateWeight = newValue + " grams";
        } else {
          this.calculateWeight = newValue / 1000 + " kg";
        }
      } else {
        this.calculateWeight = "0 grams";
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["fetchCalculationData"]),
    async init() {
      this.selectedFrom = "SriLanka";
      // await this.$store.dispatch("fetchCalculationData");
      await this.fetchCalculationData()
        .then((success) => {
          console.log(success);
          this.fetched = true;
        })
        .catch((error) => {
          console.error("error", error);
          this.fetched = false;
        });
    },
    routerPush() {
      if (
        this.selectedFrom == null &&
        this.selectedTo == null &&
        this.selectedWeight == null
      ) {
        alert("Select from to and weight");
      } else {
        this.$router.push({
          name: "calculateInfo-id",
          params: {
            selectedFrom: this.selectedFrom,
            selectedTo: this.selectedTo,
            selectedWeight: this.selectedWeight,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-card {
  background: rgba(255, 255, 255, 0.842);
  box-sizing: border-box;
  border-radius: 15px;
  width: 380px;
  height: 400px;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 2rem;
    color: rgb(8, 8, 8);
  }

  .input-field {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;

    .input-area {
      background: #fff;
      margin-top: 5%;
      padding: 12px;
      border-radius: 10px;
    }

    .input-logo {
      width: 25px;
      margin-right: 10px;
      // transparent: 0;
    }
  }

  .weight-index {
    font-size: 1.2rem;
    color: yellow;
  }

  .calculate-btn {
    background: #ffc700;
    color: #fff;
  }
}

// Media query
@media (max-width: 786px) {
  .fee-card {
    width: 300px;
  }
}
</style>