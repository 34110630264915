import { render, staticRenderFns } from "./partnerCard.vue?vue&type=template&id=647fba74&scoped=true&"
import script from "./partnerCard.vue?vue&type=script&lang=js&"
export * from "./partnerCard.vue?vue&type=script&lang=js&"
import style0 from "./partnerCard.vue?vue&type=style&index=0&id=647fba74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647fba74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VImg,VSheet,VSlideGroup,VSlideItem})
