import { db } from "../firebase";
export const noticeService = {
  get,
};

async function get() {
  console.log("notice get running...");
  return await db
    .collection("pages")
    .doc("notice")
    .get()
    .then((querySnapshot) => {
      const notice = querySnapshot.data();
      console.log("notice", notice);
      return notice;
    })
    .catch((error) => {
      console.error("error from firebase", error);
      return error;
    });
}
